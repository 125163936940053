import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import TypeButton from 'src/components/common/Buttons/TypeButton';
interface AgendaComponentProps {
    agendaTitle: string;
    agendaContent: string
}
export default function AgendaComponent(props: AgendaComponentProps) {
    const { agendaTitle, agendaContent } = props
    return (
        <div style={{ width: "100%", height: "100%", padding: "10px" }}>
            <Row>
                <Title>
                    AGENDA
                </Title>
            </Row>
            <Row>
                <Col>
                    <TypeButton text='CODE' color="#00FFCE" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <VotingTitleStyle>
                        {agendaTitle}
                    </VotingTitleStyle>
                    <VotingDescipeStyle>
                        {agendaContent}
                    </VotingDescipeStyle>
                </Col>
            </Row>
        </div>
    )
}
const Title = styled.div`
padding:10px;
font: normal normal bold 16px/24px Noto Sans CJK KR;
color: #FC5800;
font-size: 16px;
`
const VotingTitleStyle = styled.div`
padding:10px;
font: normal normal bold 24px/29px Helvetica Neue;
/* font: normal normal bold 31px/38px Helvetica Neue; */
`
const VotingDescipeStyle = styled.div`
padding: 5px;
font: normal normal normal 16px/23px 210 MGothic;
color: #D1D1D1;
opacity: 1;
`