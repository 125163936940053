import { Row } from 'antd'
import { useEffect } from 'react'
import GLOBAL from 'src/variables/globals'
import IMAGES from 'src/variables/images'
import styled from 'styled-components'
import { useState } from 'react';
import ICONS from 'src/variables/icons';
import { ProfileAbi } from 'src/modules/klaytn/abi'
import caver from 'src/modules/klaytn/caver'
import { useSelector } from 'react-redux'
import { priceToString } from 'src/utils'
import * as utils from 'src/utils';
import { RootState } from "src/redux/reducers";
export default function Profile() {
    const [profileImageUrl, setprofileImageUrl] = useState("")
    const { address, membership, vp, rewardPoint } = useSelector((state: RootState) => state.profileReducer)
    const myContract = new caver.klay.Contract(ProfileAbi as [], GLOBAL.KLAYTN.PROFILE_CONTRACT_ADDRR)

    useEffect(() => {
        address?.length > 4 &&
            myContract.methods.get().call({ from: address })
                .then(function (result: any) {
                    setprofileImageUrl(result)
                })
        return () => {
        }
    }, [address])
    return (
        <ProfileStyle>
            <Row justify="center" style={{ paddingTop: "30px", color: `${GLOBAL.COLOR.PRIMARY.BLACK}` }} />
            <Row justify='center'>
                <ProfileImageStyle>
                    {profileImageUrl.length > 0 ?
                        <img src={profileImageUrl} alt="" /> :
                        <img src={IMAGES.PROFILESAMPLE} alt="" />
                    }
                </ProfileImageStyle>
                {utils.getMembershipBadge(Number(membership))}
            </Row>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <VotingStatusStyle>
                    <VpTextStyle>
                        V.P
                    </VpTextStyle>
                    <VpValueStye>
                        {vp}
                    </VpValueStye>
                </VotingStatusStyle>
                <VotingStatusStyle>
                    <VpTextStyle>
                        R.P
                    </VpTextStyle>
                    <VpValueStye>
                        {priceToString(rewardPoint)}
                    </VpValueStye>
                </VotingStatusStyle>
            </div>
            <Row style={{ padding: "20px" }}>
                <WalletaddressStyle>
                    <img src={ICONS.COPY} alt="" />
                    <div style={{ padding: "5px" }}>
                        {address?.length > 0 ? `${address.slice(0, 6)}...${address.slice(35, 42)}` : "...."}
                    </div>
                    <div></div>
                </WalletaddressStyle>
            </Row>
        </ProfileStyle>
    )
}

const ProfileStyle = styled.div`
font-style: normal;
color:${GLOBAL.COLOR.PRIMARY.GRAY};    
font-size: 14px;
width: 85%;
height: 240px;
border: 1px solid var(--unnamed-color-ebebeb);
background: transparent linear-gradient(280deg, #FF3B00 0%, #F0CA00 100%) 0% 0% no-repeat padding-box;
border-radius: 8px;
box-shadow: 3px 3px 6px #00000029;
opacity: 1;
`
const ProfileImageStyle = styled.div`
width: 104px;
height: 105px;
& img{
    width: 100%;
    border-radius: 50%;     
}
`
const WalletaddressStyle = styled.div`
padding-left: 20px;
padding-right: 20px;
display: flex;;
background: #181824 0% 0% no-repeat padding-box;
box-shadow: 3px 3px 6px #00000033;
border-radius: 8px;
opacity: 1;
font-size: 15px;
width: 239px;
height: 42px;
display: flex;
align-items: center;
justify-content: space-between;
color: #EBEBEB;
& img{
    width: 15px;
}
`
const VpTextStyle = styled.span`
font: normal normal bold 12px/30px Noto Sans CJK KR;
color: #FFEFD5;
`
const VotingStatusStyle = styled.div`
`
const VpValueStye = styled.span`
padding: 10px;
font: normal normal medium 18px Noto Sans CJK KR;
color: #181824;
`