import React from 'react'
import CountUp, { useCountUp } from 'react-countup';
import styled, { ThemeProvider } from 'styled-components';
const VotingStaticsStyle = styled.div`
font: italic normal bold 19px Helvetica Neue;
/* font: normal normal bold 16px Noto Sans CJK KR; */
/* margin-top: 44px; */
background-color: #444448;
background: #0A0A0F 0% 0% no-repeat padding-box;
border-radius: 4px;
opacity: 1;
#quantity{
    border-radius: 3px 3px 0% 0%;
    display: flex;    
    align-items: center;
    justify-content: center;
    width: ${props => props.theme.width}%; 
    /* border: 3px solid transparent; */
    /* background-image: linear-gradient(#fff, #fff),linear-gradient(to right, red 0%,  orange 100%); */
    /* border-image: linear-gradient(to right, red 0%, orange 100%); */
    border-top: 32px solid;
     /* ${props => props.color}; */
    border-right: 10px solid transparent;  
    opacity: 1;
    background: linear-gradient(blue, pink);
    /* color:black; */
    font-weight: bold;
    /* animation-name: votingGraphAnimation;
    animation-duration: 0.8s; */
    @keyframes votingGraphAnimation {
    from {width:0%;}
    to {width: ${props => props.theme.width}%; }
    }  
}
`
const Quantity = styled.div`
position: relative;
z-index: 10;
margin-top: -29px;
margin-left:50%;
color:white;    
`
const VotingGraphWrapper = styled.div`
   animation-name: votingGraph;
  width: 105%;
  animation-duration: 1.8s;
  @keyframes votingGraph {
    from {        
        width: 0%;
        opacity: 0;
    }
    to {
        width: 105%;
        opacity: 1;
    }
  }  
`
const VotingGraph = styled.div`
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: ${props => props.theme.width}%;  
  height: 30px;
  background: ${props => props.color};
  clip-path: polygon(
    0 0,
    97% 0%,
    95% 100%,
    100% 100%,
    100% 100%,
    60% 100%,
    0 100%,
    0% 20%
  );
  padding-top: 15px;
  padding-left: 25px;
  font-weight: bold;
`
export default function AgreeGraph(props: { quantity: number, color: string }) {
    const theme = {
        width: props.quantity * 0.9 + 5,
    };
    return (
        <ThemeProvider theme={theme}>
            <VotingStaticsStyle >
                <VotingGraphWrapper  >
                    <VotingGraph color={props.color} />
                </VotingGraphWrapper>
                <Quantity>
                    <CountUp delay={0.2} end={props.quantity} />%
                </Quantity>
            </VotingStaticsStyle>
        </ThemeProvider>
    )
}

