import { Provider } from "react-redux";
import store from 'src/redux/store'
import PublicRoutes from 'src/router/PublicRoutes';
import 'antd/dist/antd.css';
function App() {
  return (
    <Provider store={store}>
      <PublicRoutes />
    </Provider>
  );
}
export default App;
