import styled from 'styled-components'
import IMAGES from 'src/variables/images';
import { Row, Space } from 'antd';
import { ReactComponent as NdLogoWhite } from 'src/assets/svg/nd-white.svg';
import { ReactComponent as PwcLogoWhite } from 'src/assets/svg/pwc-white.svg';
import { ReactComponent as BlastLogoWhite } from 'src/assets/svg/blast-logo-white.svg';
import { Link } from 'react-router-dom';
export default function ShopIntroPage() {
    return (
        <Wrapper>
            <div>
                <Row justify='space-between' align='middle'>
                    <TitleText>
                        <BlastLogoWhite width={30} height={30} />
                        <span>NFT BLAST</span>
                    </TitleText>
                </Row>
                <Row>
                    <MainText>
                        <span>MYSTERY</span><br></br>
                        <div style={{ marginTop: "-40px" }}>BOX</div>
                    </MainText>
                </Row>
                <Row>
                    <SubTitle>
                        Get mystery box with voting reward
                    </SubTitle>
                </Row>
                <Row>
                    <SubTitle>
                        Mystery box contains CODE and other NFTs!
                    </SubTitle>
                </Row>
            </div>
            <Row justify='space-between' align='middle' style={{ marginBottom: "60px" }} >
                <Space>
                    <PwcLogoWhite width={30} height={30} />
                    {/* <NdLogoWhite width={30} height={30} /> */}
                </Space>
                <Link to="/shop/desc" style={{ textDecoration: "none" }}>
                    <BuyButton >
                        BUY NOW
                    </BuyButton>
                </Link>
            </Row>
        </Wrapper>
    )
}
const Wrapper = styled.div`
padding: 30px;
color:white;
height: 100%;
/* background-image: url(${IMAGES.MYSTERYBOX_BG}); */
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;    
display: flex;
flex-direction: column;
justify-content: space-between;        
`
const TitleText = styled.div`
font: normal normal 300 45px Roboto;
display: flex;
justify-content: space-between;
align-items: center;
span{
    padding-left: 10px;
}
`
const SubTitle = styled.div`
font: normal normal 300 35px Roboto;
`
const RewardPoint = styled.div`
font: normal normal 900 20px Roboto;   
`
const MainText = styled.div`
font: normal normal bold 128px Roboto;
`
const BuyButton = styled.div`
margin:0 auto;
display: flex;
align-items: center;
justify-content: center;

width: 168px;
height: 50px;
background: transparent linear-gradient(270deg, #FF3B00 0%, #F0CA00 100%) 0% 0% no-repeat padding-box;
border-radius: 41px;
font: normal normal bold 20px Roboto;
color:#000000;
:hover{
    background-color: #181824;
    color: #ffffff;   
}    
`