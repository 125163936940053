import axios from 'axios';
import GLOBALS from 'src/variables/globals';
const accessKeyId = "YOUR_ACCESS_KEY_ID_FROM_KAS_CONSOLE";
const headers = {
    "Authorization": "Basic S0FTS1FWSlgxTjU3SkRWT0hNUEJNVVBBOmVmTElXM0hMb3ExbndlN2NnM3B0TWVlTFdwTE5ITnNtQzZsQXJQXzA=",
    "x-chain-id": `${GLOBALS.KLAYTN.CHAIN_ID}`,
    "Content-Type": "application/json"
}
export const getAllNftTokens = async (address: string) => {
    const r = await axios.get(`https://th-api.klaytnapi.com/v2/contract/nft/${GLOBALS.KLAYTN.NFT_CONTRACT_ADDR}/owner/${address}`, { headers });

    return r.data
}
export const getAllMembershipTokens = async (address: string) => {
    const r = await axios.get(`https://th-api.klaytnapi.com/v2/contract/nft/${GLOBALS.KLAYTN.MEMBERSHIP_CONTRACT_ADDR}/owner/${address}`, { headers });

    return r.data
}
export const axiosgetAllNftTokens = (address: string) => {
    return axios.get(`https://th-api.klaytnapi.com/v2/contract/nft/${GLOBALS.KLAYTN.NFT_CONTRACT_ADDR}/owner/${address}`, { headers });
}
export const axiosgetAllMembershipTokens = (address: string) => {
    return axios.get(`https://th-api.klaytnapi.com/v2/contract/nft/${GLOBALS.KLAYTN.MEMBERSHIP_CONTRACT_ADDR}/owner/${address}`, { headers });
} 
