import React, { useState } from 'react'
import styled from 'styled-components'
import MysteryBoxComponent from 'src/components/shop/MysteryboxComponent';
import { Col, Row, Space } from 'antd';
import VIDEOS from 'src/variables/video';
import { ReactComponent as PwcIconWhite } from 'src/assets/svg/pwc-white.svg';
import { ReactComponent as NdIconWhite } from 'src/assets/svg/nd-white.svg';
import { ReactComponent as CloseIcon } from 'src/assets/svg/close-white.svg';
import { ReactComponent as YesIconGreen } from 'src/assets/svg/check-icon-green.svg';
import { ReactComponent as YesIconWhite } from 'src/assets/svg/check-icon-white.svg';
import { governanceContract } from 'src/Contracts';
import IMAGES from 'src/variables/images';
import GLOBAL from 'src/variables/globals';
import * as Type from 'src/type'
import { priceToString } from 'src/utils';
import { useSelector } from 'react-redux';
import { RootState } from "src/redux/reducers";
import caver from 'src//modules/klaytn/caver';
import Modal from 'src/components/shop/Modal';

const klaytn = window.klaytn;
export default function ShopPage() {
    const [isModalOpen, setisModalOpen] = useState(false)
    const [isTransferAgree, setisTransferAgree] = useState(false)
    const { rewardPoint } = useSelector((state: RootState) => state.profileReducer)
    const [rewardToken, setrewardToken] = useState({ tokenId: 0, contractAddress: "0" })

    const getMysteryBox = (rewardType: number) => {
        setisModalOpen(false)
        governanceContract.methods.getReward_test(4, GLOBAL.KLAYTN.REWARD_CONTRACT_ADDR).send({ from: klaytn.selectedAddress, gas: 3000000 })
            .then(function (result: Type.BlockData) {
                console.log(result.events[0].address, result.events[0].raw.topics[3]);
                const tokenId = caver.utils.hexToNumber(result.events[0].raw.topics[3])
                const contractAddress = result.events[0].address
                setrewardToken({ tokenId: tokenId, contractAddress: contractAddress })
            })
            .catch((error: any) => {
                console.log(error);
                klaytn.enable();
            })
    }
    return (
        <Container>
            <Modal tokenId={rewardToken.tokenId} contractAddress={rewardToken.contractAddress} />
            {isModalOpen &&
                <TransferModal>
                    <Header>
                        <Row justify='space-between'>
                            <Title>
                                MYSTERY BOX
                            </Title>
                            <CloseIcon style={{ cursor: "pointer" }} onClick={() => {
                                setisModalOpen(false)
                            }} width={17} height={24} ></CloseIcon>
                        </Row>
                    </Header>
                    <Content>
                        <Row justify='center'>
                            <ProfileImageStyle>
                                <img src={IMAGES.CODESAMPLE} alt="" />
                            </ProfileImageStyle>
                        </Row>
                        <Row justify='center'>
                            <TokenText>
                                CODE # ???
                            </TokenText>
                        </Row>
                        <div>
                            <Row justify='center'>
                                <InputContainer >
                                    <WalletInput
                                        value={"Do you want to confirm your purchase? This page is the last page before purchasing the mystery box. The points you used disappear permanently and the decision cannot be reversed."}
                                    />
                                </InputContainer >
                            </Row>
                        </div>
                        <Row justify='start' align='middle' style={{ fontSize: "15px", width: " 90vw" }}>
                            <div style={{ paddingRight: "10px", display: "flex", alignItems: "center" }} onClick={() => setisTransferAgree(!isTransferAgree)} >
                                {isTransferAgree ? <YesIconGreen style={{ cursor: "pointer" }} width={30} height={30} /> : <YesIconWhite style={{ cursor: "pointer" }} width={30} height={30} />}
                            </div>
                            <CheckText>
                                I checked everything and will proceed.
                            </CheckText>
                        </Row>
                        <Row justify='center'>
                            <TransferButton onClick={() => getMysteryBox(1)} >
                                PURCHASE
                            </TransferButton>
                        </Row>
                    </Content>
                </TransferModal>
            }
            <Wrapper>
                <Row justify='space-between' style={{ padding: "10px", marginBottom: "10px" }} >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        MY REWARD POINT | {rewardPoint && priceToString(rewardPoint)}
                    </div>
                    <ShopHeaderButton>
                        <Col style={{ borderRight: "1px solid #707070", padding: "10px" }} >
                            <a target="_blank" href='https://www.pwc.monkeyndragon.com/'>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <PwcIconWhite width="20" height="20" />
                                    <span style={{ paddingLeft: "10px", paddingRight: "10px", color: "white" }}>CODE</span>
                                </div>
                            </a>
                        </Col>
                        <Col style={{ padding: "10px", }}>
                            <span style={{ paddingLeft: "10px" }}>0</span>
                        </Col>
                    </ShopHeaderButton>
                </Row>
                <Row gutter={[20, 20]} >
                    <Col span={8} onClick={() => { setisModalOpen(true) }}>
                        <MysteryBoxComponent discount={20} backgroundColor='#4D290F' videoSrc={VIDEOS.MYSTERY_BOX1} />
                    </Col>
                    <Col span={8}>
                        <MysteryBoxComponent discount={50} backgroundColor='#5A5A5A' videoSrc={VIDEOS.MYSTERY_BOX2} />
                    </Col>
                    <Col span={8}>
                        <MysteryBoxComponent discount={80} backgroundColor='#AD9900' videoSrc={VIDEOS.MYSTERY_BOX3} />
                    </Col>
                </Row>
            </Wrapper>
        </Container>
    )
}
const Container = styled.div`
 height: 900px;
 opacity: 1;
 animation-name: shopPageAnimation;
 animation-duration: 2s;
 @keyframes shopPageAnimation {
    from {    
        opacity: 0;    
    }
    to {        
        opacity: 1;
    }
} 
`
const Wrapper = styled.div`
color: #ffffff;
padding: 20px;
font: normal normal bold 20px Roboto;   
`
const RewardCount = styled.div`
display: flex;
align-items: center;
`
const ShopHeaderButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #707070;
border-radius: 24px;
width: 197px;
height: 44px;
.span{
    border-right: 1px solid white;
}    
`
const TransferModal = styled.div`
align-items: center;
width: 728px;
height: 600px;
background: #22242B 0% 0% no-repeat padding-box;
border-radius: 10px;
z-index: 100;
position: absolute;
top: 20%;
left: 35%;
color:#ffffff    
`
const ProfileImageStyle = styled.div`
width: 145px;
height: 145px;
& img{
    width: 100%;
    border-radius: 50%;     
}
`
const InputContainer = styled.div`
width: 90vw;
height: 12vh;
background: #15171D 0% 0% no-repeat padding-box;
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
`
const WalletInput = styled.textarea`
width: 95%;
height: 100%;
background: #15171D 0% 0% no-repeat padding-box;
font-size: 20px;
scroll:none;
border: none;
outline: none;
overflow: hidden;
`
const TransferButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 90vw;
height: 72px;
background: #088060 0% 0% no-repeat padding-box;
border-radius: 8px;
font: normal normal bold 18px Noto Sans CJK KR;

color: #FFFFFF;
:hover{
    background-color: #FD5100;    
    cursor: pointer;    
}    
`
const Header = styled.div`
padding:20px;    
`
const Content = styled.div`
width: 100%;
height: 500px;
padding:20px;
display: flex;
flex-direction: column;
justify-content: space-between;
`
const Title = styled.div`
font: normal normal bold 18px Noto Sans CJK KR;
color: #FFFFFF;
`
const TokenText = styled.div`
font: normal normal bold 24px Noto Sans CJK KR;
`
const CheckText = styled.div`
font: normal normal bold 18px Noto Sans CJK KR;
letter-spacing: 0px;
color: #FFFFFF;    
`