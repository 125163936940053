import React from 'react'
import styled from 'styled-components'
export default function TypeButton(props: { text: string, color: string }) {
    return (
        <TypeButtonStyle color={props.color}>
            <span>{props.text}</span>
        </TypeButtonStyle>
    )
}

const TypeButtonStyle = styled.div`
z-index: -100;
width: 100%;
padding: 10px;
font-weight: bold;
height: 24px;
cursor: pointer;
background: #1E1F22 0% 0% no-repeat padding-box;
box-shadow: 3px 3px 6px #00000033;
border-radius: 5px;
opacity: 1;
display: flex;
align-items: center;
justify-content: center;
& span{
    font-size: 11px;
    font-weight: bold;
    color:${props => props.color};
    opacity: 1;
}
:hover{
    background-color: #373A43;
}
`