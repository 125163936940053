import { Col, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import SearchBar from 'src/components/common/SearchBar'
import Item from 'src/components/wallet/Item'
import { GovernanceStyle } from 'src/style/Governance';
import GLOBAL from 'src/variables/globals';
import { CodeNftType } from 'src/type'
import { nftAbi, ProfileAbi } from 'src/modules/klaytn/abi'
import caver, { kaikasCaver } from 'src/modules/klaytn/caver'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import * as api from 'src/api/index';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'src/assets/svg/close-white.svg';
import { ReactComponent as YesIconGreen } from 'src/assets/svg/check-icon-green.svg';
import { ReactComponent as YesIconWhite } from 'src/assets/svg/check-icon-white.svg';
import axios from 'axios';
import * as kasModules from 'src/modules/klaytn/kas';
import { useSelector } from 'react-redux';
import { RootState } from "src/redux/reducers";
const klaytn = window.klaytn
export default function Wallet() {
    const { address } = useSelector((state: RootState) => state.profileReducer)
    const [totalNftData, settotalNftData] = useState([] as Array<CodeNftType>)
    const [displayNftCount, setdisplayNftCount] = useState(18)
    const [isModalOpen, setisModalOpen] = useState(false)
    const [transferNftData, settransferNftData] = useState({} as CodeNftType & { to: string })
    const [ispagingLoading, setisPageLoading] = useState(true)
    const [isTransferAgree, setisTransferAgree] = useState(false)
    const [isCorrectAddress, setisCorrectAddress] = useState(true)

    const kip17 = new kaikasCaver.klay.Contract(nftAbi as [], GLOBAL.KLAYTN.NFT_CONTRACT_ADDR)
    const membershipKip17 = new kaikasCaver.klay.Contract(nftAbi as [], GLOBAL.KLAYTN.MEMBERSHIP_CONTRACT_ADDR)
    const profileContract = new kaikasCaver.klay.Contract(ProfileAbi as [], GLOBAL.KLAYTN.PROFILE_CONTRACT_ADDRR)

    const senNftTx = (tokenId: string, to: string) => {
        kip17.methods.transferFrom(address, to, tokenId)
            .send({ from: address, gas: 3000000 })
            .then(function (result: any) {
                console.log(result, "result");
                setisModalOpen(false)
                alert("NFT 전송이 완료되었습니다!!!")
                window.location.reload()
            })
    }
    const senMembershipNftTx = (tokenId: string, to: string) => {
        membershipKip17.methods.transferFrom(address, to, tokenId)
            .send({ from: address, gas: 3000000 })
            .then(function (result: any) {
                console.log(result, "result");
                setisModalOpen(false)
                alert("NFT 전송이 완료되었습니다!!!")
                window.location.reload()
            })
    }
    const onChangeProfileTx = (image: string) => {
        kaikasCaver.klay.sign("프로빌 변경으로 수수료를 사용하는 것에 있어서 동의합니다", klaytn.selectedAddress).then((hash: any) => {
            profileContract.methods.set(image)
                .send({ from: klaytn.selectedAddress, gas: 3000000 })
                .then(function (result: any) {
                    window.location.reload()
                })
        });
    }
    const onTransferToken = (nftid: string) => {
        const transferdata = totalNftData.find(data => data.nftId == nftid)
        if (transferdata) {
            settransferNftData({ ...transferdata, to: "" })
        }
        setisModalOpen(true)
    }
    useBottomScrollListener(() => {
        setdisplayNftCount(displayNftCount + 8)
    });

    useEffect(() => {
        if (address?.length > 5) {
            kasModules.getAllNftTokens()?.then((values: any) => {
                settotalNftData(prev => {
                    return [...prev, ...values]
                })
            })
            api.getAllMembershipTokens(address).then(async response => {
                const PromiseList = await response.items.map((nftData: any) => {
                    return axios.get(nftData.tokenUri).then(response => { return Object.assign({}, nftData, { nftId: nftData.tokenId + nftData.tokenUri, nftType: "MEMBERSHIP", tokenName: response.data.name, tokenImage: response.data.image, }) }
                    )
                })
                await Promise.all(PromiseList).then((values: any) => {
                    settotalNftData(prev => {
                        return [...prev, ...values]
                    })
                });
            })
        }
        setTimeout(() => {
            setisPageLoading(false)
        }, 1400);
        return () => {
        }
    }, [address])
    const onSearch = () => {
    }
    return (
        <>
            {isModalOpen &&
                <TransferModal>
                    <Header>
                        <Row justify='space-between'>
                            <Title>
                                NFT TRANSFER
                            </Title>
                            <CloseIcon style={{ cursor: "pointer" }} onClick={() => {
                                setisModalOpen(false)
                            }} width={17} height={24} ></CloseIcon>
                        </Row>
                    </Header>
                    <Content>
                        <Row justify='center'>
                            <ProfileImageStyle>
                                <img src={transferNftData.tokenImage} alt="" />
                            </ProfileImageStyle>
                        </Row>
                        <Row justify='center'>
                            <TokenText>
                                <span>{transferNftData.tokenName}</span>
                            </TokenText>
                        </Row>
                        <div>
                            <Row>
                                <WalletAddressText>
                                    Wallet Addresss
                                </WalletAddressText>
                            </Row>
                            <Row justify='center'>
                                <InputContainer >
                                    <WalletInput
                                        style={isCorrectAddress ? { color: "#24C8A5" } : { color: "red" }}
                                        spellCheck={false} onChange={(e) => {
                                            if (caver.utils.isAddress(e.target.value) == false) {
                                                setisCorrectAddress(false)
                                            } else {
                                                setisCorrectAddress(true)
                                            }
                                            settransferNftData(prev => {
                                                return { ...prev, to: e.target.value }
                                            })

                                        }} />
                                </InputContainer >
                            </Row>
                            {isCorrectAddress ? <></> :
                                <Row justify='center' style={{ color: "red", marginTop: "10px" }} >
                                    wrong address plaeae check input values
                                </Row>
                            }

                        </div>
                        <Row justify='center' align='middle' style={{ fontSize: "5px" }}>
                            <div style={{ paddingRight: "10px" }} onClick={() => setisTransferAgree(!isTransferAgree)} >
                                {isTransferAgree ? <YesIconGreen style={{ cursor: "pointer" }} width={30} height={30} /> : <YesIconWhite style={{ cursor: "pointer" }} width={30} height={30} />}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                                NFT BLAST is not responsible for transmission errors caused by incorrect entry of wallet addresses.
                            </div>
                        </Row>
                        <Row justify='center'>
                            <TransferButton onClick={() => {
                                if (transferNftData.to.length == 0) {
                                    alert("주소값을 입력해주세요")
                                    return
                                }
                                if (isCorrectAddress == false) {
                                    alert("올바른 주소를 입력해주세요")
                                    return
                                }
                                if (isTransferAgree == false) {
                                    alert("필수사항을 동의 해주세요")
                                    return
                                }
                                if (transferNftData.nftType == "MEMBERSHIP") {
                                    senMembershipNftTx(transferNftData.tokenId, transferNftData.to)
                                } else {
                                    senNftTx(transferNftData.tokenId, transferNftData.to)
                                }
                            }}>
                                CONFIRM
                            </TransferButton>
                        </Row>
                    </Content>
                </TransferModal>
            }
            <GovernanceStyle>
                <Row justify="space-between" align='middle'>
                    <SearchBar onSearch={onSearch} />
                </Row>
                <Row gutter={[20, 20]} style={{ paddingRight: "300px", padding: "10px", marginTop: "10px" }} >
                    {totalNftData.slice(0, displayNftCount).map((data, i) => {
                        return (
                            <Col key={data.tokenId} span={4}>
                                {ispagingLoading ?
                                    <SkeletonTest />
                                    :
                                    <Item
                                        key={data.tokenId}
                                        onTransferToken={onTransferToken}
                                        onChangeProfileTx={onChangeProfileTx}
                                        nftId={data.nftId}
                                        tokenId={caver.utils.hexToNumber(data.tokenId)}
                                        tokenImage={data.tokenImage}
                                        tokenName={data.tokenName}
                                    />
                                }
                            </Col>
                        )
                    })}
                </Row>
            </GovernanceStyle>
        </>
    )
}
const SkeletonTest = styled.div`
    :empty {
  width: 100%;
  height: 250px; 
  border-radius: 3px;
  cursor: progress; 
  background: 
    linear-gradient(0.25turn, transparent, #fff, transparent),
    /* radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%), */
    linear-gradient(#22242B, #22242B),  
    linear-gradient(#22242B, #22242B);
    /* linear-gradient(#22242B, #22242B); */
  background-repeat: no-repeat;
  background-size: 315px 250px, 315px 180px, 300px 40px ;
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
  animation: loading 1.6s infinite;
}
background-repeat: no-repeat;
animation: loading 1.2s infinite;
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}    
`

const TransferModal = styled.div`
align-items: center;
width: 728px;
height: 600px;
background: #22242B 0% 0% no-repeat padding-box;
border-radius: 10px;
z-index: 100;
position: absolute;
top: 20%;
left: 35%;
color:#ffffff
`
const ProfileImageStyle = styled.div`
width: 148px;
height: 148px;
& img{
    width: 148px;
    height: 148px;
    border-radius: 50%; 
    border: 2px solid gray;
}
`
const InputContainer = styled.div`
width: 628px;
height: 64px;
background: #15171D 0% 0% no-repeat padding-box;
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
`
const WalletInput = styled.input`
width: 90%;
height: 80%;
background: #15171D 0% 0% no-repeat padding-box;
font-size: 20px;
border: none;
outline: none;    
`
const TransferButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 628px;
height: 72px;
background: #088060 0% 0% no-repeat padding-box;
border-radius: 8px;
font: normal normal bold 18px Noto Sans CJK KR;

color: #FFFFFF;
:hover{
    background-color: #FD5100;    
    cursor: pointer;    
}    
`
const Header = styled.div`
padding:20px;    
`
const Content = styled.div`
width: 100%;
height: 500px;
padding:20px;
display: flex;
flex-direction: column;
justify-content: space-between;
`
const Title = styled.div`
font: normal normal bold 18px Noto Sans CJK KR;
color: #FFFFFF;
`
const TokenText = styled.div`
font: normal normal bold 24px Noto Sans CJK KR;
`
const WalletAddressText = styled.div`
padding-bottom: 10px;
font: normal normal bold 18px Noto Sans CJK KR;
padding-left: 30px;
`