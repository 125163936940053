const ICONS = {
    UNDO: require('src/assets/icons/undo.png').default,
    SEARCH: require('src/assets/icons/search.png').default,
    CLOSE: require('src/assets/icons/close.png').default,
    COPY: require('src/assets/icons/copy.png').default,
    PWC_LOGO: require('src/assets/icons/pwc-logo.png').default,
    PWC_LOGO_WHITE: require('src/assets/icons/pwc-logo-white.png').default,
    ND_LOGO_WHITE: require('src/assets/icons/pwc-logo.png').default,
};

export default ICONS;
