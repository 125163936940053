import { Row } from 'antd'
import GovernanceContents from 'src/components/governance/Contents'
import SearchBar from 'src/components/common/SearchBar'
import { GovernanceStyle } from 'src/style/Governance';
import { useState } from 'react';
export default function Governance() {
    const [keyword, setkeyword] = useState("")
    const onSearch = (props: any) => {
        setkeyword(props)
    }
    return (
        <GovernanceStyle>
            <Row justify="space-between" align='middle'>
                <SearchBar onSearch={onSearch} />
            </Row>
            <Row style={{ marginTop: "10px" }} >
                <GovernanceContents keyword={keyword} />
            </Row>
        </GovernanceStyle>
    )
}