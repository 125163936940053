import React, { useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import SigInPage from 'src/pages/SignIn'
import browserHistory from 'src/modules/browserHistory'
import PrivateRouteWrap from 'src/router/RouteWrap'
import { useDispatch, useSelector } from 'react-redux'
import * as kas from 'src/api/kas';
import caver from "src/modules/klaytn/caver";
import * as utils from 'src/utils';
import { governanceContract } from 'src/Contracts/index';
import profileReducer from 'src/redux/reducers/profile';
import { RootState } from "src/redux/reducers";
import {
    SET_ADDRESS, SET_VOTINGPOWER, SET_MEMBERSHIP, SET_REWARDPOINT
} from 'src/redux/actions/profile';
const klaytn = window.klaytn;
export default function PublicRoutes() {
    const dispatch = useDispatch()
    const { address } = useSelector((state: RootState) => state.profileReducer)
    const dispatchAddress = (address: string) => {
        dispatch({ type: SET_ADDRESS, address: address })
    }
    const dispatchVotingPower = (vp: number) => {
        dispatch({ type: SET_VOTINGPOWER, vp: vp })
    }
    const dispatchMembership = (membership: number) => {
        dispatch({ type: SET_MEMBERSHIP, membership: membership })
    }

    const dispatchRewardPoint = (rewardPoint: number) => {
        dispatch({ type: SET_REWARDPOINT, rewardPoint: rewardPoint })
    }
    const loadAccountInfo = async () => {
        if (klaytn) {
            dispatchAddress(klaytn.selectedAddress)
            try {
                await klaytn.enable();
                await dispatchAddress(klaytn.selectedAddress)
                klaytn.on('accountsChanged', (accounts: string) => {
                    console.log(accounts, "accounts");
                    dispatchAddress(accounts[0])
                })
                klaytn.on('networkChanged', function (data: any) {
                    console.log(data, "networkChange");
                })
            }
            catch (error) {
                console.log('User denied account access');
            }
        } else {
            console.log('Non-Kaikas browser detected. You should consider trying Kaikas!');
        }
    }
    useEffect(() => {
        loadAccountInfo()
        return () => {
        }
    }, [])
    useEffect(() => {
        let totalVp = 0
        let membershipMulti = 1
        if (address?.length > 5) {
            const PromiseList = [kas.axiosgetAllNftTokens(klaytn.selectedAddress), kas.axiosgetAllMembershipTokens(klaytn.selectedAddress)]
            Promise.all(PromiseList).then((values: any) => {
                totalVp = values[0].data.items.length
                values[1].data.items.map((data: any) => {
                    const membershipType = utils.getMembershipMulti(Number(caver.utils.hexToNumber(data.tokenId)))
                    if (membershipType > membershipMulti) {
                        membershipMulti = membershipType
                    }
                })
                dispatchVotingPower(membershipMulti * totalVp)
                dispatchMembership(membershipMulti)
            })
            governanceContract.methods.getRewardPoint().call({ from: klaytn.selectedAddress }).then((data: any) => {
                dispatchRewardPoint(data)
            })
        }
        return () => {
        }
    }, [address])
    return (
        <main>
            <Router history={browserHistory}>
                <Switch>
                    {/* Public Route */}
                    <Route exact path="/login" component={SigInPage} />
                    {/* Private Route */}
                    <PrivateRouteWrap history={browserHistory} />
                </Switch>
            </Router>
        </main>
    )
}
