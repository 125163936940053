import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Row, Space } from 'antd';
import { ReactComponent as CloseIcon } from 'src/assets/svg/close-white.svg'
import IMAGES from 'src/variables/images';
const TransferModal = styled.div`

width: 728px;
height: 600px;
background: #22242B 0% 0% no-repeat padding-box;
border-radius: 10px;
z-index: 100;
position: absolute;
top: 20%;
left: 35%;
color:#ffffff    
`
const Header = styled.div`
padding:20px;    
`
const Title = styled.div`
font: normal normal bold 18px Noto Sans CJK KR;
color: #FFFFFF;
`
const Content = styled.div`
width: 100%;
height: 500px;
padding:20px;
display: flex;
flex-direction: column;
justify-content: space-around;
`
const ProfileImageStyle = styled.div`
width: 256px;
height: 256px;
& img{
    width: 100%;
    border-radius: 50%;     
}
`
const TokenText = styled.div`
padding-bottom: 20px;
font: normal normal bold 43px Noto Sans CJK KR;
`
const InputContainer = styled.div`
width: 90vw;
height: 12vh;
background: #15171D 0% 0% no-repeat padding-box;
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
`
const WalletInput = styled.textarea`
width: 95%;
height: 100%;
background: #15171D 0% 0% no-repeat padding-box;
font-size: 20px;
scroll:none;
border: none;
outline: none;
overflow: hidden;
`
const CheckText = styled.div`
font: normal normal bold 18px Noto Sans CJK KR;
letter-spacing: 0px;
color: #FFFFFF;    
`
const TransferButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 90%;
height: 72px;
background: #088060 0% 0% no-repeat padding-box;
border-radius: 8px;
font: normal normal bold 18px Noto Sans CJK KR;

color: #FFFFFF;
:hover{
    background-color: #FD5100;    
    cursor: pointer;    
}    
`
interface Modalprops {
    tokenId: number
    contractAddress: string
}
export default function Modal(props: Modalprops) {
    const { tokenId, contractAddress } = props
    const tokenUrl = `https://with-nft.s3.ap-northeast-2.amazonaws.com/generative/${tokenId}.png`
    const [isRewardModalOpen, setisRewardModalOpen] = useState(false)
    useEffect(() => {
        if (tokenId > 0) {
            setisRewardModalOpen(true)
        }
        return () => {

        }
    }, [tokenId])
    return (
        <>
            {isRewardModalOpen && <TransferModal>
                <Header>
                    <Row justify='space-between'>
                        <Title>
                            MYSTERY BOX
                        </Title>
                        <CloseIcon
                            onClick={() => setisRewardModalOpen(!isRewardModalOpen)}
                            style={{ cursor: "pointer" }} width={17} height={24} ></CloseIcon>
                    </Row>
                </Header>
                <Content>
                    <Row justify='center'>
                        <ProfileImageStyle>
                            <img src={tokenUrl} alt="" />
                        </ProfileImageStyle>
                    </Row>
                    <Row justify='center'>
                        <TokenText>
                            CODE # {tokenId}
                        </TokenText>
                    </Row>
                    <Row justify='center'>
                        <TransferButton onClick={() => setisRewardModalOpen(!isRewardModalOpen)} >
                            OK
                        </TransferButton>
                    </Row>
                </Content>
            </TransferModal>}
        </>
    )
}
