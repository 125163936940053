export const governanceAbi = [
	{
		"constant": false,
		"inputs": [
			{
				"name": "_type",
				"type": "uint256"
			},
			{
				"name": "_rewardContract",
				"type": "address"
			}
		],
		"name": "getReward",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_agendaTitle",
				"type": "string"
			},
			{
				"name": "_agendaContent",
				"type": "string"
			},
			{
				"name": "_expireDate",
				"type": "uint256"
			},
			{
				"name": "_startDate",
				"type": "uint256"
			}
		],
		"name": "makeAgenda",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"name": "decisionBranch",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_owner",
				"type": "address"
			}
		],
		"name": "transferOwner",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "_agendaNo",
				"type": "uint256"
			}
		],
		"name": "votingResult",
		"outputs": [
			{
				"name": "",
				"type": "uint256[3]"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "address"
			}
		],
		"name": "reward",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "_agendaNo",
				"type": "uint256"
			}
		],
		"name": "getAgenda",
		"outputs": [
			{
				"components": [
					{
						"name": "agendaNo",
						"type": "uint256"
					},
					{
						"name": "agendaTitle",
						"type": "string"
					},
					{
						"name": "agendaContent",
						"type": "string"
					},
					{
						"name": "expireDate",
						"type": "uint256"
					},
					{
						"name": "startDate",
						"type": "uint256"
					}
				],
				"name": "",
				"type": "tuple"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "getMembershipContractAddress",
		"outputs": [
			{
				"name": "",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_type",
				"type": "uint256"
			},
			{
				"name": "_rewardContract",
				"type": "address"
			}
		],
		"name": "getReward_test",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_address",
				"type": "address"
			}
		],
		"name": "addMembershipContractAddress",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "getAllAgendas",
		"outputs": [
			{
				"components": [
					{
						"name": "agendaNo",
						"type": "uint256"
					},
					{
						"name": "agendaTitle",
						"type": "string"
					},
					{
						"name": "agendaContent",
						"type": "string"
					},
					{
						"name": "expireDate",
						"type": "uint256"
					},
					{
						"name": "startDate",
						"type": "uint256"
					}
				],
				"name": "",
				"type": "tuple[]"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "_fromAddress",
				"type": "address"
			},
			{
				"name": "_stakingContractAddress",
				"type": "address"
			}
		],
		"name": "getVotedCount",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_agendaNo",
				"type": "uint256"
			},
			{
				"name": "_votingType",
				"type": "uint8"
			},
			{
				"components": [
					{
						"name": "nftCtonractAddress",
						"type": "address"
					},
					{
						"name": "tokenId",
						"type": "uint256"
					},
					{
						"name": "voterAddress",
						"type": "address"
					}
				],
				"name": "_tokenInfoList",
				"type": "tuple[]"
			},
			{
				"name": "_membershipId",
				"type": "uint256"
			},
			{
				"name": "_stakingContractAddress",
				"type": "address"
			}
		],
		"name": "votingToAgendaMulti",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"name": "votingCard",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"name": "votingCardIdKey",
		"outputs": [
			{
				"name": "nftCtonractAddress",
				"type": "address"
			},
			{
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"name": "voterAddress",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_agendaNo",
				"type": "uint256"
			}
		],
		"name": "deleteAgenda",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "getRewardPoint",
		"outputs": [
			{
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_fromAddress",
				"type": "address"
			},
			{
				"name": "_nftContractAddress",
				"type": "address"
			},
			{
				"name": "_stakingContractAddress",
				"type": "address"
			}
		],
		"name": "withDraw",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"name": "_toAddress",
				"type": "address"
			},
			{
				"name": "_votingPower",
				"type": "uint256"
			}
		],
		"name": "addVotingPower",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"name": "_membershipContractAddress",
				"type": "address"
			},
			{
				"name": "_nftCtonractAddress",
				"type": "address"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "constructor"
	}
]