import VIDEOS from 'src/variables/video'
import styled from 'styled-components'
export default function Main() {
    return (
        <MainWrapper>
            <section className="pc-main" >
                <video
                    style={{ borderRadius: "20px" }}
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={VIDEOS.MAIN_VIDEO} type="video/mp4" />
                </video>
            </section>
        </MainWrapper>
    )
}
const MainWrapper = styled.div`
padding-left:5px;
padding-right: 20px;
background-color: #0B0A10 !important;
video{
  width: 100%;
  height: 100%
}   
color:"white" 
`