import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd';
import styled from 'styled-components'
import VotingGraph from 'src/components/governanceDesc/VotingGraph';
import { Statistic, Row, Col } from 'antd';
import querystring from 'query-string'
import { useLocation } from 'react-router-dom';
import { governanceCallContract } from 'src/Contracts/index';
import app from 'src/firebse';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
const { Countdown } = Statistic;
export default function DashBoardComponent(props: { expireDate: number }) {
    const location = useLocation();
    const deadline = props.expireDate
    const queryStringValue = querystring.parse(location.search) as { id: string }

    const [votingResult, setvotingResult] = useState({ agree: 0, disagree: 0, abstention: 0 })
    const [isGraphLoading, setisGraphLoading] = useState(true)
    const [submitText, setsubmitText] = useState({ agree: "AGREE", disagree: "DISAGREE", abstention: "ABSTENTION" })

    // const db = getFirestore(app);
    // async function getAgendas(db: any) {
    //     const agendaCol = collection(db, 'hello');
    //     const agendaDocs = await getDocs(agendaCol);
    //     const agendaTypeList = agendaDocs.docs.map(doc => doc.data());
    //     return agendaTypeList;
    // }
    useEffect(() => {
        governanceCallContract.methods.votingResult(queryStringValue.id).call().then(function (result: any) {
            setvotingResult({ agree: Number(result[0]), disagree: Number(result[1]), abstention: Number(result[2]) })
        })
        setTimeout(() => {
            setisGraphLoading(false)
        }, 300);
        // const totalAgenda = getAgendas(db)
        // totalAgenda.then(result => {
        //     const agenda = result[0].data.find((data: any) => data.id == 1)
        //     setsubmitText({ agree: agenda.agree, disagree: agenda.disagree, abstention: agenda.abstention })
        // })
        return () => {
        }
    }, [])

    return (
        <Container>
            <Row>
                <Title>
                    DASHBOARD
                </Title>
            </Row>
            <Row >
                <Col span={12} >
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "280px" }} >
                        <Row justify='center'  >
                            <VotingTotalText>
                                <VotingTotalTitle>
                                    Total
                                </VotingTotalTitle>
                                <VotingTotalResult>
                                    {votingResult.agree + votingResult.disagree + votingResult.abstention}
                                </VotingTotalResult>
                            </VotingTotalText>
                        </Row>
                        <Row justify='center'  >
                            <Col span={8} >
                                <VotingCount>
                                    <VotingCountTitle>
                                        {submitText.agree}
                                    </VotingCountTitle>
                                    <VotingCountContent>
                                        {votingResult.agree}
                                    </VotingCountContent>
                                </VotingCount>
                            </Col>
                            <Col span={8} >
                                <VotingCount>
                                    <VotingCountTitle>
                                        {submitText.disagree}
                                    </VotingCountTitle>
                                    <VotingCountContent>
                                        {votingResult.disagree}
                                    </VotingCountContent>
                                </VotingCount>
                            </Col>
                            <Col span={8} >
                                <VotingCount>
                                    <VotingCountTitle>
                                        {submitText.abstention}
                                    </VotingCountTitle>
                                    <VotingCountContent>
                                        {votingResult.abstention}
                                    </VotingCountContent>
                                </VotingCount>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={12} style={{ fontWeight: "bold", paddingRight: "10px", paddingLeft: "10px" }}>
                    <CountDownTitle>Remaining Time</CountDownTitle>
                    <Countdown value={deadline} format={"D [Days] H : m : s:SSS"} />
                    <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                        {submitText.agree}
                    </div>
                    {isGraphLoading ?
                        <Skeleton active />
                        : <>
                            <VotingGraph color=' #088060' quantity={
                                Number(votingResult.agree) > 0 ? Math.round((votingResult.agree / Number(votingResult.agree + votingResult.disagree + votingResult.abstention)) * 100) : 0
                            } />
                            <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                                {submitText.disagree}
                            </div>
                            <VotingGraph color=' #FF0D45' quantity={
                                Number(votingResult.disagree) > 0 ? Math.round((votingResult.disagree / Number(votingResult.agree + votingResult.disagree + votingResult.abstention)) * 100) : 0} />
                            <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                                {submitText.abstention}
                            </div>
                            <VotingGraph color=' #3B3B3B' quantity={
                                Number(votingResult.abstention) > 0 ? Math.round((votingResult.abstention / Number(votingResult.agree + votingResult.disagree + votingResult.abstention)) * 100) : 0} />
                        </>}
                </Col>
            </Row >
        </Container >
    )
}
const Container = styled.div`
width: 100%;
height: 100%;
padding-bottom: 35px;
background: #15171D 0% 0% no-repeat padding-box;
box-shadow: 3px 3px 6px #00000029;
border-radius: 12px;
opacity: 1;
`
const Title = styled.div`
padding:20px;
font: normal normal bold 16px Noto Sans CJK KR;
color: #FC5800;
font-size: 16px;
`
const VotingTotalText = styled.div`
width: 100%;
height: 100px;
margin-bottom: 30px;
display: flex;
justify-content: space-between;
flex-direction: column;
align-items: center;
font: normal normal bold 56px Helvetica Neue;
`
const VotingTotalTitle = styled.div`
font-size: 24px;
color: #FFFFFF;     
`
const VotingTotalResult = styled.div`
font-size: 80px;    
padding: 20px;
`
const VotingCount = styled.div`
text-align: center; 
`
const CountDownTitle = styled.div`
font: normal normal bold 20px/25px Helvetica Neue;    
color: #FFFFFF;
opacity: 1;
font-size: 20px;    
`
const VotingCountTitle = styled.div`  
padding : 20px;
font: normal normal bold 18px Noto Sans CJK KR;
`
const VotingCountContent = styled.div`
font: normal normal bold 30px Helvetica Neue;    
`