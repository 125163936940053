import { Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
interface propsType {
    expireDate: string
}
export default function TimeOverBottomWrapper(props: propsType) {
    return (
        <ButtonWrapper>
            <Row justify='center' style={{ paddingTop: "10px" }}>
                WITHDRAWABLE NFT
            </Row>
            <Row justify='center' style={{ padding: "10px" }}>
                0 EA
            </Row>
            <Row justify='center'>
                <WithDrawButton >WITHDRAW</WithDrawButton>
            </Row>
        </ButtonWrapper>
    )
}
const WithDrawButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
background: #088060 0% 0% no-repeat padding-box;
border-radius: 4px;
width: 90%;
font: normal normal bold 12px/17px Noto Sans CJK KR;
color: #FFFFFF;
height: 42px;    
`

const ButtonWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 5px;
margin-top: 20px;
height: 20%;
background-color:#15171D ;
border-radius: 14px; 
font: normal normal bold 14px/17px Helvetica Neue;    
`
