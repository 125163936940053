import React from 'react'
import styled from 'styled-components'
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import app from 'src/firebse';
const Wrapper = styled.div`
color: #FA6D00;
font: normal normal bold 40px/49px Helvetica Neue;
width : 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`
export default function StakingPage() {
    const db = getFirestore(app);
    const agendaCol = collection(db, 'hello');
    const agendaDocs = getDocs(agendaCol);

    const totalAgenda = getAgendas(db)

    async function getAgendas(db: any) {
        const agendaCol = collection(db, 'hello');
        const agendaDocs = await getDocs(agendaCol);
        const agendaTypeList = agendaDocs.docs.map(doc => doc.data());
        return agendaTypeList;
    }
    console.log(totalAgenda.then(data => {
        console.log(data);
    }), "totalAgenda");

    return (
        <Wrapper>
            <div>STAKING IS COMING SOON</div>
        </Wrapper>
    )
}
