import React from 'react'
import styled from 'styled-components'
export default function BridgePage() {
    return (
        <Wrapper>
            <div>BRIDGE IS COMING SOON</div>
        </Wrapper>
    )
}
const Wrapper = styled.div`
color: #FA6D00;
font: normal normal bold 40px/49px Helvetica Neue;
width : 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`