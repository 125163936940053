import { Row } from 'antd'
import styled from 'styled-components'
import { ReactComponent as PwcLogo } from 'src/assets/svg/pwc-logo.svg';
import { ApiFilled, MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Space } from 'antd';
import caver from 'src/modules/klaytn/caver'
import { kaikasCaver } from 'src/modules/klaytn/caver'
import { ProfileAbi, nftAbi } from 'src/modules/klaytn/abi'
import GLOBAL from 'src/variables/globals';
import { useEffect, useState } from 'react';
import * as api from 'src/api/index';
import IMAGES from 'src/variables/images';
import Skeleton from 'react-loading-skeleton'
const ItemStyle = styled.div`
display: flex;
background: #181824 0% 0% no-repeat padding-box;
opacity: 1;
width: 100%;
color:#EBEBEB;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
`
const ItemImageStyle = styled.div`
padding-top: 10%;
width: 50%;
& img{
    width: 100%;
    border-radius: 50%;    
}
`
const ItemTextStyle = styled.div`
padding: 10px;
`
const ItemHeader = styled.div`
font: normal normal bold 10px/15px Noto Sans CJK KR;
color: #CBCBCB;
display: flex;
align-items: center;
justify-content: space-between;
padding-left: 10px;
width: 100%;
height: 40px;
background: #22242B 0% 0% no-repeat padding-box;
border-radius: 4px 4px 0px 0px;    
`
const ItemBody = styled.div`    
`
const ItemFooter = styled.div`
width: 100%;
height: 56px;
font: normal normal medium 12px/23px Noto Sans CJK KR;
color: #EBEBEB;
opacity: 1;
font-size: 12px;
background: #22242B 0% 0% no-repeat padding-box;
    
`
const VotingPowerText = styled.div`
padding-right: 10px;
font: normal normal bold 12px/23px Noto Sans CJK KR;
color: #EBEBEB;
`
const MenuItem = styled.div`

:hover{
    background-color: #22242B;
    color:#ffffff
    
}    
`
const SkeletonTest = styled.div`
    :empty {
  width: 100%;
  height: 250px; 
  border-radius: 3px;
  cursor: progress; 
  background: 
    linear-gradient(0.25turn, transparent, #fff, transparent),
    /* radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%), */
    linear-gradient(#22242B, #22242B),  
    linear-gradient(#22242B, #22242B);
    /* linear-gradient(#22242B, #22242B); */
  background-repeat: no-repeat;
  background-size: 315px 250px, 315px 180px, 300px 40px ;
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
  animation: loading 1.6s infinite;
}
background-repeat: no-repeat;
animation: loading 1.2s infinite;
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}    
`

const klaytn = window.klaytn
export default function Item(props: { nftId: string, tokenId: string, tokenImage: string, onTransferToken: any, tokenName: string, onChangeProfileTx: any }) {
    const [isImageLoading, setisImageLoading] = useState(true)
    console.log(isImageLoading, "isImageLoading");

    const changeMyProfileImage = (data: any) => {
        props.onChangeProfileTx(data)
    }

    const senNftTx = (nftId: string, to: string) => {
        props.onTransferToken(nftId)
    }
    const menu = (
        <Menu theme="dark">
            <Menu.Item key={0}>
                <MenuItem key={0} onClick={() => changeMyProfileImage(props.tokenImage)}>
                    프로필 변경
                </MenuItem>
                <MenuItem key={1} onClick={() => senNftTx(props.nftId, "0xaafDA34BEC35D88ac91FB75C50aA5cc3B60b782C")}>
                    전송하기
                </MenuItem>
            </Menu.Item>

        </Menu>
    );
    return (
        <>
            <ItemStyle  >
                <ItemHeader>
                    <PwcLogo width={17} height={24} ></PwcLogo>
                    <Dropdown overlayStyle={{ color: "red" }} overlay={menu} placement="bottomLeft">
                        <div style={{ padding: "10px", cursor: "pointer" }}>
                            <MoreOutlined width={10} />
                        </div>
                    </Dropdown>
                </ItemHeader>
                <ItemBody>
                    <Row justify='center'>
                        <ItemImageStyle>
                            <img onLoad={(e) => {
                                console.log(e);
                            }} src={props.tokenImage} alt="" />
                        </ItemImageStyle>

                    </Row>
                    <Row justify='end'>
                        <VotingPowerText>
                            VP:1
                        </VotingPowerText>
                    </Row>
                </ItemBody>
                <ItemFooter >
                    <ItemTextStyle>
                        <Space>
                            <div>
                                {props.tokenName}
                            </div>
                        </Space>
                    </ItemTextStyle>
                </ItemFooter>
            </ItemStyle >
        </>
    )
}
