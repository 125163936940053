import React from 'react'
import { Route, Switch } from 'react-router-dom'
import GovernancePage from 'src/pages/Governance'
import GovernanceDescPage from 'src/pages/GovernanceDesc'
import WalletPage from 'src/pages/Wallet'
import MainPage from 'src/pages/Main'
import ShopMainPage from 'src/pages/ShopMainPage'
import ShopIntroPage from 'src/pages/ShopIntroPage'
import StakingPage from 'src/pages/StakingPage'
import BridgePage from 'src/pages/BridgePage'
import MeltPage from 'src/pages/MeltPage'

export default function PrivateRoutes() {
    return (
        <Switch>
            <Route exact path="/main" component={MainPage} />
            <Route exact path="/governance" component={GovernancePage} />
            <Route exact path="/governance/desc" component={GovernanceDescPage} />
            <Route exact path="/wallet" component={WalletPage} />
            <Route exact path="/shop" component={ShopIntroPage} />
            <Route exact path="/shop/desc" component={ShopMainPage} />
            <Route exact path="/staking" component={StakingPage} />
            <Route exact path="/bridge" component={BridgePage} />
            <Route exact path="/melt" component={MeltPage} />
        </Switch>
    )
}
