import React from 'react'
import VIDEOS from 'src/variables/video'
import styled from 'styled-components'

const MainWrapper = styled.div`
color:"red"
video{
  width: 100%;
}   
color:"white" 
`
export default function SignIn() {
    return (
        <MainWrapper>
            <section className="mobile-main">
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={VIDEOS.MAIN_LAVA} type="video/mp4" />
                </video>
            </section>
            <div>hello</div>
        </MainWrapper>
    )
}
