import styled from 'styled-components'
import GLOBAL from 'src/variables/globals'
export const GovernanceStyle = styled.div`
color:${GLOBAL.COLOR.PRIMARY.RED};
margin: 10px;
font-size: 24px;
`
export const VotingPowerStyle = styled.div`
font: italic normal bold 24px/29px Helvetica Neue;    
padding-right: 4px;

`