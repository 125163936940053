import { Redirect, Route, useLocation } from "react-router-dom";
import { History } from "history";
import BasicLayout from 'src/layout/Basic';
import querystring from 'query-string'
interface IPublicRoutesProps {
    history: History;
}
function PrivateRouteWrap({ history, ...rest }: IPublicRoutesProps) {
    const location = useLocation();
    const pathname = location.pathname
    return (
        <Route
            {...rest}
            render={
                ({ location }) =>
                    pathname !== "/" ?
                        <BasicLayout />
                        :
                        // useinfo 없을 시 redirect
                        <Redirect
                            to={{
                                pathname: "/main",
                                state: { from: location },
                            }}
                        />}
        />
    );
}
export default PrivateRouteWrap;
