import styled from 'styled-components'
import GLOBALS from 'src/variables/globals'
import { Row } from 'antd'
import SiderNavBar from 'src/components/sider/NavBars';
import Profile from 'src/components/sider/Profile';
import { ReactComponent as BlastLogo } from 'src/assets/svg/blast-logo.svg';
import { Link } from 'react-router-dom';
import IMAGES from 'src/variables/images';
const SiderStyle = styled.div`
color: ${GLOBALS.COLOR.PRIMARY.RED};
font: italic normal bold 18px Helvetica Neue;
background-color: ${GLOBALS.COLOR.PRIMARY.DARK};
font-size: 18px;
height: 100%;
`
const AdsectionStyle = styled.img`
margin-top: 20px;
width: 100%;
height: 100%;
display: flex;

background: transparent linear-gradient(105deg, #FF3B00 0%, #F0CA00 100%) 0% 0% no-repeat padding-box;
justify-content: center;
align-items: center;
box-shadow: 3px 3px 6px #00000033;
border-radius: 8px;
color: #181824;
font-size: 18px;
text-align: left;
font: normal normal bold 18px Helvetica Neue;
`
const Title = styled.div`
padding-left:10px;
color: ${GLOBALS.COLOR.PRIMARY.RED};   
font: italic normal bold 18px Helvetica Neue; 
`

export default function Sider() {
    return (
        <SiderStyle>
            <Row justify='center' style={{ padding: "40px 20px 40px 10px", borderBottom: `1px solid #000000 ` }}>
                <BlastLogo width={24} height={24} />
                <Link to="/main" style={{ textDecoration: "none" }}>
                    <Title >NFT BLAST</Title>
                </Link>
            </Row>
            <SiderNavBar />
            <Row justify='center' >
                <Profile />
            </Row>
            <Row justify="center" style={{ paddingBottom: "100px", paddingLeft: "20px", paddingRight: "20px" }} >
                <a target="_blank" href="https://www.youtube.com/watch?v=SkpYCrF0PPU">
                    <AdsectionStyle src={IMAGES.ADD_SECTION} />
                </a>
            </Row>
        </SiderStyle>
    )
}