import { Row } from 'antd'
import React from 'react'
import styled from 'styled-components';
const StyleWrapper = styled.div`  
    padding:5px;       
`
const Title = styled.div`
    font: normal normal bold 20px/25px Helvetica Neue;    
    color: #FFFFFF;
    opacity: 1;
    font-size: 20px;    
`
const Content = styled.div`
    font: normal normal normal 16px/23px 210 MGothic;
    letter-spacing: 0px;
    color: #D1D1D1;
    opacity: 1;    
`
export default function AgendaDescrip(props: { title: string, content: string }) {
    const { title, content } = props
    return (
        <StyleWrapper>
            <Row>
                <Title>
                    {title}
                </Title>
            </Row>
            <Row >
                <Content>
                    {content}
                </Content>
            </Row>
        </StyleWrapper>
    )
}
