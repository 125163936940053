import React, { useRef } from 'react'
import styled from 'styled-components'
import VIDEOS from 'src/variables/video'
import { Col, Row } from 'antd'
import ICONS from 'src/variables/icons';
import { ReactComponent as PwcIconWhite } from 'src/assets/svg/pwc-white.svg';
import { ReactComponent as NdIconWhite } from 'src/assets/svg/nd-white.svg';
import GLOBAL from 'src/variables/globals';
import { Abi, governanceAbi } from 'src/modules/klaytn/abi';
import caver from "src/modules/klaytn/caver";
import * as Type from 'src/type';
const klaytn = window.klaytn
export default function MysteryboxComponent(props: { videoSrc: string, backgroundColor: string, discount: number }) {
    const videoRef = useRef<HTMLVideoElement>(null);
    const myContract = new caver.klay.Contract(governanceAbi as [], GLOBAL.KLAYTN.VOTING_CONTRACT_ADDR)

    const sendGetReward = (votingType: number) => {
        myContract.methods.getReward(1, GLOBAL.KLAYTN.REWARD_CONTRACT_ADDR).send({ from: klaytn.selectedAddress, gas: 3000000 })
            .then(function (result: Type.BlockData) {
                console.log(result);
            })
            .catch((error: any) => {
                console.log(error);
                klaytn.enable();
            })
    }
    return (
        <Container color={props.backgroundColor} >
            <Header>
                <Row justify='space-between' align='middle' style={{ paddingTop: "20px", paddingBottom: "20px" }}  >
                    <Col>
                        <Title>
                            BRONZE BOX
                        </Title>
                    </Col>
                    <Col>
                        <PointCount>
                            16POINT
                        </PointCount>
                    </Col>
                </Row>
                <RewardDescrip>
                    <PwcIconWhite width="30" height="30" />
                    <Row>
                        <span>CODE</span> <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>|</span>00%
                    </Row>
                </RewardDescrip>
            </Header>
            <Body>
                <MainVideoWrapper
                    ref={videoRef}
                    autoPlay
                    muted
                    loop
                >
                    <source src={props.videoSrc} type="video/mp4" />
                </MainVideoWrapper>
            </Body>
            <Footer>
                <OpenButton
                //  onClick={() => {
                //     sendGetReward(1)
                // }} 
                >
                    BUY & OPEN
                </OpenButton>
            </Footer>
        </Container >
    )
}
const Container = styled.div`
 animation-name: mysteryboxAnimation;
 animation-duration: 2s;
 @keyframes mysteryboxAnimation {
    from {
        margin-top:80px;
        opacity: 0;    
    }
    to {
        margin-top:0px;
        opacity: 1;
    }
}   
/* width: 502px; */
padding:20px;
border-radius: 10px;
height: 100%;
width: 100%;
/* background:transparent linear-gradient(151deg, #4D290F 0%, #000000 13%, #000000 93%, #4D290F77 100%) 0% 0% no-repeat padding-box; */
background: transparent linear-gradient(154deg, ${props => props.color} 0%, #000000 13%, #000000 93%, ${props => props.color} 100%) 0% 0% no-repeat padding-box;
`
const Header = styled.div`

`
const Body = styled.div` 
height:400px;
display: flex;
justify-content: center;
align-items: center;
`
const Footer = styled.div`  
font: normal normal 300 40px/48px Bebas Neue;
padding:10px;
display: flex;
justify-content: space-between;
color:white;
`
const Title = styled.div`

font: normal normal bold 35px Roboto;    
color: #FFFFFF;
`
const PointCount = styled.div`
font: normal normal bold 20px Roboto;
`
const Price = styled.div`
padding:20px;
font: normal normal bold 118px/156px Roboto;
color: #FFFFFF;
`
const LogoContainer = styled.div`
img{
    width: 32px;
}    
`
const Point = styled.div``
const IsOpen = styled.div``
const MainVideoWrapper = styled.video`
    width: 100%;      
    height: 400px;
    object-fit: cover;
    object-position: center center;
`
const RewardDescrip = styled.div`
padding-top: 10px;
padding-bottom: 10px;
`
const OpenButton = styled.div`
display: flex;
justify-content: center;
align-items: center;

width: 441px;
height: 80px;
background: #000000 0% 0% no-repeat padding-box;
border: 1px solid #4D290F;
border-radius: 8px;
font: normal normal bold 24px Roboto;
:hover{
    background: transparent linear-gradient(105deg, #FF3B00 0%, #F0CA00 100%) 0% 0% no-repeat padding-box;
    color:black;
    cursor: pointer;
    
}    
`