import React, { ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'
import GLOBALS from 'src/variables/globals'
import { Row } from 'antd'
import browserHistory from 'src/modules/browserHistory';
import { useLocation } from 'react-router-dom';
import * as DUMMYS from 'src/dummy/index';
import { CSSTransition } from 'react-transition-group';
const SiderContentStyled = styled.div`    
color:${GLOBALS.COLOR.PRIMARY.GRAY};    
font-size: 14px;
font-style: normal;
height: 450px;
`
const BasicNavMenuStyle = styled.div`
-webkit-transition: all 500ms ease-in;
display: flex;
align-items: center;
width: 100%;
height: 42px;
font-weight: bold;
padding-left:15%;
font: normal normal medium 16px Helvetica Neue;
color: #868686;
:hover{
    background-color: #373A43;
}

`
const NavMenuContainer = styled.div`
width: 100%;
`
const CurrentNavMenuStyle = styled.div`
width: 100%;
height: 42px;
font-weight: bold;
padding-left:15%;

font-family: normal normal medium 16px Helvetica Neue;
background: transparent linear-gradient(280deg, #FF3B00 0%, #F0CA00 100%) 0% 0% no-repeat padding-box;
color: #0A0A0F;
opacity: 1;
display: flex;
justify-content:space-between;
align-items: center;
color:black;
animation-name: example;
animation-duration: 0.8s;

@keyframes example {
  from {
      opacity:0; 
      width:30px;
      /* height: 0px; */
}
  to {
    opacity: 1;
    width: 100%;
    /* height: 50px; */
}}
`
interface TransitionProps {
    in?: boolean;
    timeout: number;
    enter?: string;
    enterFrom?: string;
    enterTo?: string;
    leave?: string;
    leaveFrom?: string;
    leaveTo?: string;
    children: ReactNode;
}

export default function SiderContents() {
    // const { enter, enterFrom, enterTo, leave, leaveFrom, leaveTo } = props;
    const location = useLocation();
    const routeToPage = (route: string) => {
        browserHistory.push(`/${route}`)
    }
    const [trigger, setTrigger] = useState(0);
    function addClasses(classes: string[], ref: React.RefObject<HTMLDivElement>) {
        ref.current?.classList.add(...classes);
    }

    function removeClasses(classes: string[], ref: React.RefObject<HTMLDivElement>) {
        ref.current?.classList.remove(...classes);
    }
    // const enterClasses = splitClasses(enter);
    // const enterFromClasses = splitClasses(enterFrom);
    // const enterToClasses = splitClasses(enterTo);
    // const leaveClasses = splitClasses(leave);
    // const leaveFromClasses = splitClasses(leaveFrom);
    // const leaveToClasses = splitClasses(leaveTo);
    const nodeRef = React.useRef<HTMLDivElement>(null);
    return (
        <SiderContentStyled>
            {DUMMYS.pageLists.map((pageList, i) => {
                return (
                    <div key={i} style={{ width: "100%", paddingTop: "10px", cursor: "pointer" }} onClick={() => routeToPage(pageList.route)}>
                        {location.pathname.split("/")[1] === pageList.route ?
                            <CSSTransition
                                classNames="alert"
                                in={location.pathname.split("/")[1] === pageList.route}
                                nodeRef={nodeRef}
                                timeout={300}
                            // unmountOnExit
                            // onEnter={() => {
                            //     addClasses([...enterClasses, ...enterFromClasses], nodeRef);
                            // }}
                            // onEntering={() => {
                            //     removeClasses(enterFromClasses, nodeRef);
                            //     addClasses(enterToClasses, nodeRef);
                            // }}
                            // onEntered={() => {
                            //     removeClasses([...enterToClasses, ...enterClasses], nodeRef);
                            // }}
                            // onExit={() => {
                            //     addClasses([...leaveClasses, ...leaveFromClasses], nodeRef);
                            // }}
                            // onExiting={() => {
                            //     removeClasses(leaveFromClasses, nodeRef);
                            //     addClasses(leaveToClasses, nodeRef);
                            // }}
                            // onExited={() => {
                            //     removeClasses([...leaveToClasses, ...leaveClasses], nodeRef);
                            // }}
                            >
                                <NavMenuContainer>
                                    <CurrentNavMenuStyle>
                                        {pageList.title}
                                    </CurrentNavMenuStyle>
                                </NavMenuContainer>

                            </CSSTransition>
                            :
                            <BasicNavMenuStyle>
                                {pageList.title}
                            </BasicNavMenuStyle>
                        }
                    </div>)
            })}
        </SiderContentStyled>
    )
}
function splitClasses(string: string = ""): string[] {
    return string.split(" ").filter((s) => s.length);
}