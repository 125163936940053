// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD2dbNQqZiFXwjcoS83eSeGLebq4foZUSo",
    authDomain: "e-clone-8c762.firebaseapp.com",
    projectId: "e-clone-8c762",
    storageBucket: "e-clone-8c762.appspot.com",
    messagingSenderId: "699604017657",
    appId: "1:699604017657:web:c2050905a596a3d94e5d28"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app