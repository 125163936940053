const isDev = true;
const GLOBAL = {
    isDev: isDev,
    // contractAddress: "0x9054c23198c5ba373cd879063cbd715952526b51",
    IMAGE: {
        LOGO: require("src/assets/images/projectwith.png").default,
    },
    COLOR: {
        PRIMARY: {
            WHITE: "#FFFFFF",
            GRAY: "#868686",
            GREEN: "#02AD79",
            LIGHT: "#D5C2E5",
            RED: "#FA6600",
            PINK: "#FF6E6E",
            BLUE: "#1763B6",
            MAIN: "#9e79be",
            DARK: "#15171C",
            BLACK: "#0A0A0F",
        },
    },
    SERVER: {
        RELEASE: isDev ? "http://127.0.0.1:8080" : 'http://52.79.37.117:8080', // 실서버 서버
        IMAGE: isDev ? 'http://' : '', //Keon http://211.204.237.78:8000  m company my ip  192.168.35.253:8080 
    },
    KLAYTN: {
        // 0x790542620A567d4Ca2C95A73Aa5183005F833B05
        CHAIN_ID: isDev ? "1001" : "8217",
        STAKING_CONTRACT_ADDR: isDev ? "0x8F4f2ce924e8EFaC8fcefD0F6303ce9F9167BBCa" : "0xE4Eb479c3e32B5Cd38F0bf6Bc9049f9E6ba9cEdE",
        REWARD_CONTRACT_ADDR: isDev ? "0x55F206bA2E4fef47599424168B46CB78cDE792Ff" : "0xE4Eb479c3e32B5Cd38F0bf6Bc9049f9E6ba9cEdE",
        // prev staking 0xE4Eb479c3e32B5Cd38F0bf6Bc9049f9E6ba9cEdE
        //staking 0x3E17430AE868C34ecCf0e98beC023972B1C33Bc8
        PROFILE_CONTRACT_ADDRR: isDev ? "0x2A2142c51308c6c1269A4B2e654CaE19559d0E85" : "0xD4e4bdea4bEA2B9a2840Bad6b17a484C1d971546",
        NFT_CONTRACT_ADDR: isDev ? "0x963BB00CdDCCed34Cd6efB666f651558626911a6" : "0x9054c23198c5ba373cd879063cbd715952526b51",
        MEMBERSHIP_CONTRACT_ADDR: isDev ? "0x1Da6632a8655279d7336f67D88E7147F7fB9F014" : "0x9054c23198c5ba373cd879063cbd715952526b51",
        //prev voting contract 0x82cCD36A7EAd261CE3bbD16346222d46eD0d54c7
        //0xf4Ac807AC68BbE6BF9285842e1F128605ca645eE
        //0x1C8Ae3b108E6A3b7eb38C09C6D25bDf162ED70f6
        VOTING_CONTRACT_ADDR: isDev ? "0x697ce361A11Ea7bD79DAD7B2024f09124556bf51" : "0x3e1A9249E51e20200AAB2a9F649254932e2E9537"
        //master wallet 0x61692833c1F720beBCc5325684aa56Ef0dEBE15E
    }
};
export default GLOBAL 