const IMAGES = {
    CODESAMPLE: require('src/assets/images/code.png').default,
    PROFILESAMPLE: require('src/assets/images/profile.png').default,
    NEWPROFILESAMPLE: require('src/assets/images/newprofile.png').default,
    MYSTERYBOX_BG: require('src/assets/images/mysteryboxbg.png').default,
    BLACK_SCREEN: require('src/assets/images/black-screen.png').default,
    ADD_SECTION: require('src/assets/images/adsection.png').default,
    FOOTER_LOGO: require('src/assets/images/footer-logo.png').default
};

export default IMAGES;
