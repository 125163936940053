import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import GLOBAL from 'src/variables/globals'
import styled from 'styled-components'
import { getMembershipMulti } from 'src/modules/common';
import { CodeNftType } from 'src/type';
import app from 'src/firebse';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
interface propsType {
    myMembershipCard?: { tokenIdNum: number };
    codeNftCardInCarts: Array<CodeNftType>;
    onSendVotingTx: any

}
export default function BottomWrapper(props: propsType) {
    const { myMembershipCard, codeNftCardInCarts, onSendVotingTx } = props
    const [submitText, setsubmitText] = useState({ agree: "AGREE", disagree: "DISAGREE", abstention: "ABSTENTION" })
    // const db = getFirestore(app);

    async function getAgendas(db: any) {
        const agendaCol = collection(db, 'hello');
        const agendaDocs = await getDocs(agendaCol);
        const agendaTypeList = agendaDocs.docs.map(doc => doc.data());
        return agendaTypeList;
    }

    useEffect(() => {
        // const totalAgenda = getAgendas(db)
        // totalAgenda.then(result => {
        //     const agenda = result[0].data.find((data: any) => data.id == 1)
        //     setsubmitText({ agree: agenda.agree, disagree: agenda.disagree, abstention: agenda.abstention })
        // })

        return () => {

        }
    }, [])

    return (
        <ButtonWrapper >
            <Row justify='center' >
                <Col span={8}>
                    <div style={{ textAlign: "center", fontSize: "10px" }}>
                        SELECTED NFT
                    </div>
                </Col>
                <Col span={8}>
                    <div style={{ textAlign: "center", fontSize: "10px", marginLeft: "-8px" }}>
                        {myMembershipCard && getMembershipMulti(myMembershipCard.tokenIdNum) > 1 ?
                            <span style={{ color: "#F79708" }}>
                                MEMBERSHIP
                            </span>
                            :
                            <span >
                                MEMBERSHIP
                            </span>}
                    </div>
                </Col>

                <Col span={8}>
                    <div style={{ textAlign: "center", fontSize: "10px" }}>
                        VOTE
                    </div>
                </Col>
            </Row>
            <Row justify='center' style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                <Col span={6} >
                    <div style={{ textAlign: "center", fontSize: "18px", marginLeft: "-8px" }}>
                        {codeNftCardInCarts.length}
                    </div>
                </Col>
                <Col span={2}>
                    <div style={{ textAlign: "center", marginLeft: "-2px" }}>
                        x
                    </div>
                </Col>
                <Col span={6}>
                    <div style={{ textAlign: "center", fontSize: "18px", }}>
                        {myMembershipCard ?
                            <span style={{ color: "#F79708" }}>{getMembershipMulti(myMembershipCard.tokenIdNum)} </span>
                            :
                            1}
                    </div>
                </Col>
                <Col span={2}>
                    <div style={{ textAlign: "center" }}>
                        =
                    </div>
                </Col>
                <Col span={6}>
                    <div style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "18px", paddingRight: "2px" }}>+{codeNftCardInCarts.length * (myMembershipCard ? getMembershipMulti(myMembershipCard.tokenIdNum) : 1)}</span>
                        <span style={{ fontSize: "12px" }}>
                            V.P
                        </span>
                    </div>
                </Col>
            </Row>

            <Row gutter={[10, 10]} style={{ paddingTop: "10px" }}>
                <Col span={8} style={{ cursor: "pointer" }} >
                    <VotingButton
                        onClick={() => onSendVotingTx(0)}
                        color='#088060'>
                        {submitText.agree}
                    </VotingButton>
                </Col>
                <Col span={8}
                    onClick={() => onSendVotingTx(1)}
                    style={{ cursor: "pointer" }
                    } >
                    <VotingButton color='#FF0D45'  >
                        {submitText.disagree}
                    </VotingButton>
                </Col>
                <Col span={8}
                    onClick={() => onSendVotingTx(2)}
                    style={{ cursor: "pointer" }} >
                    <VotingButton color='#3B3B3B'  >
                        {submitText.abstention}
                    </VotingButton>
                </Col>
            </Row>
        </ButtonWrapper>
    )
}
const ButtonWrapper = styled.div`
padding:20px 10px 0px 10px;
margin-top: 20px;
height: 17%;
background-color:#15171D ;
border-radius: 14px; 
font: normal normal bold 14px/17px Helvetica Neue;    
/* padding: 10px; */
`

const VotingButton = styled.div`
:hover{
    background-color: ${GLOBAL.COLOR.PRIMARY.RED};
}
font: normal normal bold 12px/17px Noto Sans CJK KR;
color:#ffffff;
background: ${props => props.color} ;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 36px;
border-radius: 4px;
`