import GLOBALS from 'src/variables/globals';
import moment, { Moment } from 'moment';
import axios from 'axios';
import * as dateFns from "date-fns";
import { ReactComponent as GoldBadge } from 'src/assets/svg/gold-badge.svg';
import { ReactComponent as SilverBadge } from 'src/assets/svg/silver-badge.svg';
import { ReactComponent as BronzeBadge } from 'src/assets/svg/bronze-badge.svg';

export const getMembershipBadge = (membershipGrade: number) => {
    if (membershipGrade == 4) {
        return (
            <GoldBadge style={{ position: "absolute", top: 650, left: 160 }} width={50} height={50} />)
    } else if (membershipGrade == 3) {
        return <SilverBadge style={{ position: "absolute", top: 650, left: 160 }} width={50} height={50} />
    } else if (membershipGrade == 2) {
        return <BronzeBadge style={{ position: "absolute", top: 650, left: 160 }} width={50} height={50} />
    } else {
        return (<></>)
    }
}
export const getMembershipMulti = (membershipId: number) => {
    if (membershipId >= 1501 &&
        membershipId <= 1544) {
        return 4
    } else if (membershipId >= 1545 &&
        membershipId <= 1569) {
        return 3
    } else if (membershipId >= 1570 &&
        membershipId <= 1596) {
        return 2
    } else {
        return 1
    }
}
export const isVoteTimeOut = (expireDate: string) => {
    const currentTimestamp = new Date().getTime();
    return Number(expireDate) * 1000 > currentTimestamp

}
// 로고 등록시
export function priceToString(price: number) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const transTimestampToTimestamp00 = (moment: Moment) => {
    const m = moment.utcOffset(0)
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    m.toISOString()
    m.format()
    const result = m.unix() * 1000
    return result
}

export const getCurrentTime = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    const hours = ('0' + today.getHours()).slice(-2);
    const minutes = ('0' + today.getMinutes()).slice(-2);
    const seconds = ('0' + today.getSeconds()).slice(-2);
    const dateString = year + '-' + month + '-' + day + '-' + hours + ':' + minutes + ':' + seconds;

    return dateString
}

export const transHashtagsObjectToString = (hashtags: Array<{ hashTags: string }>) => {
    let hashTags = ""
    hashtags.forEach((data, i) => {
        if (i === hashtags.length - 1) {
            hashTags = hashTags + data.hashTags
        } else {
            hashTags = hashTags + data.hashTags + ","
        }
    })
    return hashTags
}

export const transTimestamptoYYYY = (timestamp: number) => {
    const date_not_formatted = new Date(timestamp);
    const formatted_string = date_not_formatted.getFullYear();
    return formatted_string;
}
export const transTimestamptoYYMMDD = (timestamp: number) => {
    let date_not_formatted = new Date(timestamp);

    let formatted_string = date_not_formatted.getFullYear() + "-";

    if (date_not_formatted.getMonth() < 9) {
        formatted_string += "0";
    }
    formatted_string += (date_not_formatted.getMonth() + 1);
    formatted_string += "-";

    if (date_not_formatted.getDate() < 10) {
        formatted_string += "0";
    }
    formatted_string += date_not_formatted.getDate();

    return formatted_string;

}

export function transStringDateToTimestamp(value: string) {
    return dateFns.format(new Date(value), "yyyy-MM-dd")
}

export const trans_YYMMDDtoTimestamp = (yymmddBirthDate: string) => {
    const year = Number(yymmddBirthDate.substring(0, 4))
    const month = Number(yymmddBirthDate.substring(5, 7))
    const day = Number(yymmddBirthDate.substring(8, 10))

    return new Date(year, month - 1, day).getTime();

}
export const transYYMMDDtoTimestamp = (yymmddBirthDate: string) => {
    const year = Number(yymmddBirthDate.substring(0, 4))
    const month = Number(yymmddBirthDate.substring(4, 6))
    const day = Number(yymmddBirthDate.substring(6, 8))

    return new Date(year, month - 1, day).getTime();

}
export function transDateFormat(value: string) {
    return new Date(value).getTime();
}
export function transZeroLengthNumberString(value: string | undefined): number {
    if (value === '' || value === undefined) {
        return 0;
    }
    else return parseInt(value.replace(/[^0-9]/g, ""));
}
export async function imageUploadJson(file: any): Promise<JSON> {
    console.log(file)
    const data = new FormData();
    data.append('file', file);
    let result = {} as JSON;
    result = await axios.post(`${GLOBALS.SERVER.IMAGE}`, data)
        .then(res => {
            return result = res.data
        })
        .catch(error => {
            console.log(error);
        });
    return Promise.resolve(result);
}
export async function fileUploadJson(file: any): Promise<JSON> {
    const data = new FormData();
    data.append('file', file);
    let result = {} as JSON;
    result = await axios.post(`${GLOBALS.SERVER.IMAGE}`, data)
        .then(res => {
            return result = res.data
        })
        .catch(error => {
            console.log(error);
        });
    return Promise.resolve(result);
}
export function headers(): any {
    const accessToken = window.sessionStorage.getItem('accessToken');
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    }
}
export function transElipse(text: string, number: number) {
    if (text.length > number) {
        return text.slice(0, number) + '...'
    }
    return text;
}
export function transMoneyFormat(value: number): string {
    return (value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function transPhoneFormat(value: number): string {
    return (value).toString().replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
}
export function transPhoneNumberFormat(value: number): string {
    const rtn = value.toString()
        .replace(/[^0-9]/g, '')
        .replace(
            /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
            '$1-$2-$3',
        )
        .replace('--', '-');
    return rtn;
}
export function transDateTimeFormat(date: Date, format?: string): string {
    return moment(date).format(format === undefined ? 'YYYY-MM-DD HH:mm' : format);
}
