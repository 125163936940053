import {
    SET_ADDRESS_ACTION,
    SET_VP_ACTION,
    SET_MEMBSRSHIP_ACTION,
    SET_REWARDPOINT_ACTION,
    SET_ADDRESS, SET_VOTINGPOWER,
    SET_MEMBERSHIP, SET_REWARDPOINT
} from 'src/redux/actions/profile';
import { produce } from 'immer';
export interface ProfileState {
    address: string,
    vp: number,
    membership: string,
    rewardPoint: number
}
const initialState: ProfileState = {
    address: "...",
    vp: 0,
    membership: "normal",
    rewardPoint: 0,
}

type ProfileStateActions = SET_ADDRESS_ACTION | SET_VP_ACTION | SET_MEMBSRSHIP_ACTION | SET_REWARDPOINT_ACTION
const profileReducer = (state = initialState, action: ProfileStateActions) => {
    switch (action.type) {
        case SET_ADDRESS:
            return { ...state, address: action.address }
        case SET_VOTINGPOWER:
            return { ...state, vp: action.vp }
        case SET_MEMBERSHIP:
            return { ...state, membership: action.membership }
        case SET_REWARDPOINT:
            return { ...state, rewardPoint: action.rewardPoint }
        default:
            return state
    }
}
export default profileReducer;