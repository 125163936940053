const VIDEOS = {
    MYSTERY_BOX: require('src/assets/videos/mystery-box.mp4').default,
    MYSTERY_BOX1: require('src/assets/videos/mysterybox1.mp4').default,
    MYSTERY_BOX2: require('src/assets/videos/mysterybox2.mp4').default,
    MYSTERY_BOX3: require('src/assets/videos/mysterybox3.mp4').default,
    MAIN_LAVA: require('src/assets/videos/lava.mp4').default,
    MAIN_VIDEO: require('src/assets/videos/nftblastfinal.mp4').default,
};

export default VIDEOS;
