import { Col, Row, Image } from 'antd';
import IMAGES from 'src/variables/images';
import styled from 'styled-components';
import { ReactComponent as DiscordIcon } from 'src/assets/svg/discord.svg';
import { TwitterOutlined } from '@ant-design/icons';

export default function Footer() {
    return (
        <FooterWrapper>
            <Row className="pc-footer" justify="center" style={{ paddingTop: "50px" }} >
                <Col span={12} lg={7} xl={6} xxl={4} style={{ textAlign: "left" }}>
                    <span style={{ color: "white", fontWeight: "bold" }}>개인정보 취급방침 &nbsp;&nbsp; |&nbsp;&nbsp; 서비스 이용약관</span>
                    <br />
                    <div style={{ color: "#CECECE", width: "500px", marginTop: "10px" }}>2022. All rights reserved by Monkey and Dragon Limited</div>
                </Col>
                <Col span={2} />
                <Col span={6} lg={7} xl={6} xxl={4} style={{ textAlign: "left" }}>
                    <div>
                        <span style={{ color: "white", fontWeight: "bold", paddingRight: "5px" }}>CONTACT </span>
                        <span style={{ color: "#CECECE" }}>contact@projectwith.io</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }} >
                        <div style={{ color: "white", fontWeight: "bold", paddingRight: "15px", paddingTop: "10px" }}>
                            <a target="_blank" href='https://discord.gg/U3tY9qKvC8'>
                                <DiscordIcon width="30" height="30" />
                            </a>

                        </div>
                        <div style={{ color: "white", fontSize: "30px", fontWeight: "bold", paddingRight: "5px", paddingTop: "2px" }}>
                            <a style={{ color: "white" }} target="_blank" href='https://mobile.twitter.com/flfcode'>
                                <TwitterOutlined />
                            </a>

                        </div>
                    </div>
                </Col>
            </Row>
            {/* <section className="mobile-footer" >
                <Row justify="center" align="bottom"  >
                    <Image style={{ width: "87px" }} preview={false} src={IMAGES.FOOTER_LOGO} />
                </Row>
                <Row justify="center" align="top">
                    <div style={{ color: "#CECECE", padding: "5px" }}>2022. All rights reserved by Monkey and Dragon Limited</div>
                </Row>
            </section> */}
        </FooterWrapper >
    )
}
const FooterWrapper = styled.div`    
    a{    
    color: #CECECE;
    }
    background-color: #000000;      
    @media (max-width: 800px) {  
        .pc-footer{
            display: none;
        } 
        .mobile-footer{
            padding-top: 20px;
            padding-bottom: 20px;
            height: 74px;
            font-size: 6px !important;
        }             
    } 
    @media (max-width: 573px) {      
        .mobile-footer{
           margin-bottom: 74px;
        }             
    } 
    @media (min-width: 800px) { 
        .pc-footer{
            height: 170px;
        }  
        .mobile-footer{
            display: none;
        }          
    }     
`