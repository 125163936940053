import { Col, Row, Skeleton } from 'antd'
import styled from 'styled-components'
import Item from 'src/components/governance/Item'
import { useEffect, useState } from 'react';
import * as Type from 'src/type';
import { governanceCallContract } from 'src/Contracts/index';
import { useSelector } from 'react-redux';
import { RootState } from "src/redux/reducers";
const Wrapper = styled.div`
background-color: #0B0A0F;
padding: 10px;
border-radius: 14px;
opacity: 1;
width: 100%;
height: 100%;
`
const SkeletonWrapper = styled.div`
width: 100%;
height: 100%;     
`

const SkeletonTest = styled.div`
    :empty {
  width: 100%;
  height: 250px; 
  cursor: progress; 
  background: 
    linear-gradient(0.25turn, transparent, #fff, transparent),
    /* radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%), */
    linear-gradient(#22242B, #22242B),  
    linear-gradient(#22242B, #22242B);
    /* linear-gradient(#22242B, #22242B); */
  background-repeat: no-repeat;
  background-size: 100% 250px, 100% 180px, 100% 40px ;
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
  animation: loading 1.s infinite;
}
background-repeat: no-repeat;
/* background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px; */
animation: loading 1.5s infinite;

@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}    
`
interface ContentProps {
    keyword: string
}
export default function Contents(props: ContentProps) {
    const { keyword } = props
    const [agendas, setagendas] = useState([] as Array<Type.Agenda>)
    const [fiterAgendas, setfiterAgendas] = useState([] as Array<Type.Agenda>)
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [agendaResult, setagendaResult] = useState([])
    const { address } = useSelector((state: RootState) => state.profileReducer)
    useEffect(() => {
        const filteredAgendas = agendas.filter(function (agenda) {
            return agenda.agendaTitle.toLowerCase().indexOf(keyword) > -1;
        });
        setfiterAgendas(filteredAgendas)
        return () => {
        }
    }, [keyword])
    useEffect(() => {
        governanceCallContract.methods.getAllAgendas().call()
            .then(function (result: any) {
                const filterResult = result.filter((data: any) => Number(data[0]) > 0)
                setagendas(filterResult)
                setfiterAgendas(filterResult)
                const PromiseList = filterResult.map((agendaData: any) => {
                    return governanceCallContract.methods.votingResult(agendaData[0]).call()
                })
                Promise.all(PromiseList).then((values: any) => {
                    setagendaResult(values)

                });
            })
            .catch((response: any) => {
                console.log(response);
            })
        setTimeout(() => {
            setIsPageLoading(false)
        }, 1000);
        return () => {
        }
    }, [])
    return (
        !isPageLoading ?
            <Wrapper>
                <Row gutter={[20, 20]}>
                    {fiterAgendas.map((data, i) =>
                        data.agendaTitle.length > 0 &&
                        <Col key={data.agendaNo} md={24} xl={12} xxl={8} >
                            <Item agendaNo={data.agendaNo} agendaResult={agendaResult[i]} title={data.agendaTitle} expireDate={data.expireDate} startDate={data.startDate} />
                        </Col>
                    )}
                </Row>
            </Wrapper>
            :
            <SkeletonWrapper>
                <Row gutter={[20, 20]}>
                    {agendas.map(data =>
                        <Col key={data.agendaNo} span={8} >
                            <Skeleton active paragraph={{ rows: 6 }} />
                        </Col>
                    )}
                </Row>
            </SkeletonWrapper>
    )
}
