import { Col, Row } from 'antd'
import React, { useState } from 'react'
import ICONS from 'src/variables/icons'
import styled from 'styled-components'
import { ReactComponent as SearchIcon } from 'src/assets/svg/search.svg';
const SearchBarStyle = styled.div`
margin-left: 10px;
width: 695px;
height: 54px;
border: 2px solid #15171D;
background-color: #0B0A0F;
opacity: 1;
display: flex;
padding: 10px;
align-items: center;
`
const SearchIconStyle = styled.div`
display: flex;
padding-left: 7px;
padding-top: 3px;
padding-right: 7px;
/* padding:10px */
`
const SearchInputStyle = styled.div`
& input{    
    font-size: 20px;
    width: 500px;    
    background-color: #0B0A0F;
    border:none;
    outline-style: none;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #545454;
    opacity: 1; /* Firefox */
}
}
`
interface SearchBarProps {
    onSearch: any
}
export default function SearchBar(props: SearchBarProps) {
    const { onSearch } = props
    const [keyword, setkeyword] = useState("")
    console.log(keyword);
    return (
        <SearchBarStyle>
            <SearchIconStyle>
                <SearchIcon width="20" height="20" />
            </SearchIconStyle>
            <SearchInputStyle>
                <input onChange={(e) => {
                    setkeyword(e.target.value)
                    onSearch(e.target.value)
                }} type="text" placeholder='SEARCH' />
            </SearchInputStyle>
        </SearchBarStyle>
    )
}
