export const SET_ADDRESS = 'SET_ADDRESS_ACTION' as const;
export const SET_VOTINGPOWER = 'SET_VP_ACTION' as const;
export const SET_MEMBERSHIP = 'SET_MEMBERSHIP_ACTION' as const;
export const SET_REWARDPOINT = 'SET_REWARDPOINT_ACTION' as const;

export interface SET_ADDRESS_ACTION {
    type: typeof SET_ADDRESS,
    address: string
}
export interface SET_VP_ACTION {
    type: typeof SET_VOTINGPOWER,
    vp: number
}
export interface SET_MEMBSRSHIP_ACTION {
    type: typeof SET_MEMBERSHIP,
    membership: string
}
export interface SET_REWARDPOINT_ACTION {
    type: typeof SET_REWARDPOINT,
    rewardPoint: number
}