import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import GLOBAL from 'src/variables/globals';
import { Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';
declare global {
    interface Window { klaytn: any; }
}
const HeaderStyle = styled.div`
    padding:39px;
    padding-left: 30px;
    height: 105px;    
    background-color: #0B0A0F;
    border-bottom: solid 2px #181824;
    color:${GLOBAL.COLOR.PRIMARY.RED};    
    font: normal normal bold 18px/23px Helvetica Neue;
`
export default function Header() {
    const [pathName, setpathName] = useState("")
    const location = useLocation()

    useEffect(() => {
        setpathName(location.pathname.split("/")[1].toUpperCase())
        return () => {
        }
    })
    return (
        <HeaderStyle>
            <Row justify="space-between">
                <Col>{pathName}</Col>
                <Col>
                    {GLOBAL.isDev ? "TEST VERSION" : ""}
                </Col>
            </Row>
        </HeaderStyle>
    )
}
