import * as kas from 'src/api/kas'
const klaytn = window.klaytn
export const getAllNftTokens = () => {
    if (klaytn) {
        const getAllNftTokens = kas.getAllNftTokens(klaytn.selectedAddress)
        const result = getAllNftTokens.then(async response => {
            await response.items?.map(async (data: any) => {
                const token = data.tokenUri
                const split = token.split("/")
                const jsonSplit = split[4].split(".")
                jsonSplit[1] = "png"
                const png = jsonSplit.join(".")
                split[3] = "generative"
                split[4] = png
                const tokenImage = split.join("/")
                Object.assign(data, { nftType: "CODE", nftId: data.tokenId + data.tokenUri, tokenImage: tokenImage, tokenName: `M16 0RA #${jsonSplit[0]}` })
            })
            return response.items
        }).catch(error => {
            return error
        })
        return result
    }
}
export const getAllMembershipNftTokens = () => {
    if (klaytn) {
        const getAllNftTokens = kas.getAllNftTokens(klaytn.selectedAddress)
        const result = getAllNftTokens.then(response => {
            response.items?.map((data: any) => {
                const token = data.tokenUri
                const split = token.split("/")
                if (split[3] == "membership_nft") {

                }
                split[5].split("_")
                console.log(split, "split Data");
            })
            return response.items
        }).catch(error => {
            return error
        })
        return result
    }
}