export const getMembershipMulti = (membershipId: number) => {
    if (membershipId >= 1501 &&
        membershipId <= 1544) {
        return 4
    } else if (membershipId >= 1545 &&
        membershipId <= 1569) {
        return 3
    } else if (membershipId >= 1570 &&
        membershipId <= 1596) {
        return 2
    } else {
        return 1
    }

}