import axios from 'axios';
import GLOBALS from 'src/variables/globals';
const accessKeyId = "YOUR_ACCESS_KEY_ID_FROM_KAS_CONSOLE";
const headers = {
    "Authorization": "Basic S0FTS1FWSlgxTjU3SkRWT0hNUEJNVVBBOmVmTElXM0hMb3ExbndlN2NnM3B0TWVlTFdwTE5ITnNtQzZsQXJQXzA=",
    "x-chain-id": `${GLOBALS.KLAYTN.CHAIN_ID}`,
    "Content-Type": "application/json"
}
export const getAllNftTokens = async (address: string) => {
    const r = await axios.get(`https://th-api.klaytnapi.com/v2/contract/nft/${GLOBALS.KLAYTN.NFT_CONTRACT_ADDR}/owner/${address}`, { headers });

    return r.data
}
const data = {
    sender: "0xaafDA34BEC35D88ac91FB75C50aA5cc3B60b782C",
    owner: "0xaafDA34BEC35D88ac91FB75C50aA5cc3B60b782C",
    to: "0x61692833c1F720beBCc5325684aa56Ef0dEBE15E"

}
export const postNftToken = async (address: string, tokenId: string) => {
    const r = await axios.post(`https://kip17-api.klaytnapi.com/v1/contract/${GLOBALS.KLAYTN.NFT_CONTRACT_ADDR}/token/0x18`,
        data,
        { headers }
    );
    return r.data
}
// https://kip17-api.klaytnapi.com/v1/contract/{contract-address-or-alias}/token/{token-id}
export const getAllMembershipTokens = async (address: string) => {
    const r = await axios.get(`https://th-api.klaytnapi.com/v2/contract/nft/${GLOBALS.KLAYTN.MEMBERSHIP_CONTRACT_ADDR}/owner/${address}`, { headers });

    return r.data
}