import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import querystring from 'query-string'
import { Col, Row } from 'antd';
import { ThemeProvider } from 'styled-components';
import AgendaType from 'src/components/governance/AgendaDescrip';
import * as Type from 'src/type';
import { transTimestamptoYYMMDD } from 'src/utils';
import DashBoardComponent from 'src/components/governanceDesc/DashBoardComponent';
import MyVotingPowerComponent from 'src/components/governanceDesc/MyVotingPowerComponent';
import * as dummy from 'src/dummy/index';
import { governanceCallContract } from 'src/Contracts/index';
import AgendaComponent from 'src/components/governanceDesc/AgendaComponent'
import { ReactComponent as DiscordIcon } from 'src/assets/svg/discord.svg';
export default function GovernanceDesc() {
    const location = useLocation();
    const [agendaInfo, setagendaInfo] = useState({} as Type.Agenda);
    const queryStringValue = querystring.parse(location.search) as { id: string }

    useEffect(() => {
        if (queryStringValue) {
            governanceCallContract.methods.getAllAgendas().call()
                .then(function (result: Array<Type.Agenda>) {
                    const agendaInfo = result.find(data => data.agendaNo === queryStringValue.id) as Type.Agenda
                    setagendaInfo(agendaInfo)
                })
        }
        return () => {
        }
    }, [])
    return (
        <ThemeProvider theme={theme}>
            {agendaInfo.agendaNo &&
                <Row style={WrapperStyle}>
                    <Col xxl={16} span={14}>
                        <Row style={AgendaStyle}>
                            <Col span={12} >
                                <AgendaComponent agendaTitle={agendaInfo.agendaTitle} agendaContent={agendaInfo.agendaContent} />
                            </Col>
                            <Col span={12} >
                                <div style={{ width: "100%", height: "100%", padding: "20px 0px 0px 5px", marginTop: "30px" }}>
                                    {dummy.governaceDescMenuDummy.map((data, i) => {
                                        return <AgendaType key={i} title={data.title} content={data.content} />
                                    })}
                                    <AgendaType title="SUBMIT TIME" content={`${transTimestamptoYYMMDD(Number(agendaInfo.startDate) * 1000)} 22:00 ~ ${transTimestamptoYYMMDD(Number(agendaInfo.expireDate) * 1000)} 22:00 [ UTC + 0 ] `} />
                                    <a target="_blank" href='https://discord.gg/U3tY9qKvC8'>
                                        <DiscordIcon style={{ margin: "10px" }} width="30" height="30" />
                                    </a>
                                </div>
                            </Col>
                        </Row >
                        <Row>
                            <DashBoardComponent expireDate={Number(agendaInfo.expireDate) * 1000} />
                        </Row>
                    </Col>
                    <Col xxl={8} span={10} >
                        <MyVotingPowerComponent expireDate={agendaInfo.expireDate} />
                    </Col>
                </Row>
            }
        </ThemeProvider>
    )
}
const theme = {
    fg: "palevioletred",
    bg: "white"
};
const WrapperStyle = { padding: "0px 0px 0px 10px", color: "white" } as React.CSSProperties
const AgendaStyle = { margin: "20px 0px 20px 0px", borderRadius: "14px", background: "#15171D 0% 0% no-repeat padding-box", boxShadow: "3px 3px 6px #00000029 " } as React.CSSProperties
