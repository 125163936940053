import { Col, Row } from 'antd'
import { transTimestamptoYYMMDD } from 'src/utils'
import styled from 'styled-components'
import { ReactComponent as PwcLogo } from 'src/assets/svg/pwc-logo.svg';
import TypeButton from 'src/components/common/Buttons/TypeButton';
import AgreeGraph from 'src/components/governance/AgreeGraph';
import GLOBAL from 'src/variables/globals'
import * as Type from 'src/type';
import { Link } from 'react-router-dom';
import { governanceContract } from 'src/Contracts';
import { useEffect, useState } from 'react';
import * as Utils from 'src/utils';
interface ItemProps {
    title: string,
    expireDate: string,
    agendaNo: string,
    startDate: string
    agendaResult: string[]
}
const klaytn = window.klaytn;
export default function Item(props: ItemProps) {
    const { expireDate, agendaNo, startDate, title, agendaResult } = props
    const currentTimestamp = new Date().getTime();
    const [voteQuantity, setvoteQuantity] = useState(0)

    useEffect(() => {
        if (agendaResult?.length > 0) {
            const quantity = Math.ceil((Number(agendaResult[0]) / (Number(agendaResult[0]) + Number(agendaResult[1]) + Number(agendaResult[2]))) * 100)
            setvoteQuantity(quantity)
        }
        return () => {
        }
    }, [])
    const withDraw = () => {
        governanceContract.methods.withDraw(klaytn.selectedAddress, GLOBAL.KLAYTN.NFT_CONTRACT_ADDR, GLOBAL.KLAYTN.STAKING_CONTRACT_ADDR).send({ from: klaytn.selectedAddress, gas: 3000000 })
            .then(function (result: Type.BlockData) {
                console.log(result, "withdraw result");
            })
            .catch((error: any) => {
                console.log(error, "withdraw 실패");
                klaytn.enable();
            })
    }
    return (
        <ItemWrapper >
            <SubTitleStyle>
                <Row justify="space-between">
                    <Col>
                        <PwcLogo width={24} height={24} />
                        <Row justify="end">
                            <AgendaNo>
                                Agenda No.{agendaNo}
                            </AgendaNo>
                        </Row>
                    </Col>
                    <Col>
                        {currentTimestamp > Number(expireDate) * 1000 ?
                            <TypeButton color={"gray"} text="완료" />
                            :
                            currentTimestamp > Number(startDate) * 1000 ?
                                <TypeButton color='#00FFCE' text="진행중" />
                                :
                                <TypeButton color='#F36600' text="진행예정" />
                        }
                        <Row>
                            <VotingPowerText>
                                Reward : 1RP
                            </VotingPowerText>
                        </Row>

                    </Col>
                </Row>
            </SubTitleStyle>

            <Link to={{ pathname: "governance/desc", search: `?id=${agendaNo}` }} >
                <Row justify='space-between' >
                    <Col span={18}>
                        <AgendaTitleStyle>
                            <div style={{ fontSize: "20px" }}>{title.slice(0, 59)} {props.title.length > 60 && "..."}  </div>
                        </AgendaTitleStyle>
                    </Col>
                </Row>
            </Link>
            <TypeContainer>
                <Row gutter={10}>
                    <Col>
                        <TypeButton color='#00FFCE' text="CODE" />
                    </Col>
                </Row>
            </TypeContainer>
            {/* <AgreeGraph color=' #FA4F01;' quantity={voteQuantity > 0 ? voteQuantity : 0} /> */}
            {Utils.isVoteTimeOut(expireDate)
                ? <AgreeGraph color=' #FA4F01;' quantity={voteQuantity > 0 ? voteQuantity : 0} />
                : <WithDrawButton onClick={withDraw} >
                    WITHDRAW
                </WithDrawButton>}
        </ItemWrapper >
    )
}

const ItemWrapper = styled.div`
padding: 16px;
border-radius: 12px;
background-color:#15171C;
height: 100%;
`
const SubTitleStyle = styled.div`
padding-top: 10px;
/* padding-bottom: 10px; */
color:#6C6C74;
font-size: 14px;
`
const AgendaTitleStyle = styled.div`
padding-top: 20px;
height: 80px;
margin-top: -6px;
color: #FFFFFF;
opacity: 1;
font-size: 24px;
/* padding-bottom: 40px; */
font: normal normal bold 31px Helvetica Neue;
& span{
    line-height: 0.5;
}
`
const TypeContainer = styled.div`
    padding-top:20px;
    padding-bottom:20px;
`
const AgendaNo = styled.div`
margin-top: 5px;
display: flex;
justify-content: flex-end;
font: normal normal bold 14px Helvetica Neue;
color:gray        
`
const VotingPowerText = styled.div`
margin-top: 10px;
display: flex;
justify-content: flex-end;
font: normal normal bold 14px Helvetica Neue;
color:gray    
`
const WithDrawButton = styled.div`
cursor: pointer;
width: 100%;
height: 32px;
font: normal normal bold 12px Noto Sans CJK KR;
background: #088060 0% 0% no-repeat padding-box;
box-shadow: 3px 3px 6px #00000029;
opacity: 1;
color: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
:hover{
    background-color: ${GLOBAL.COLOR.PRIMARY.RED};
}
`