import profileReducer from "./profile";
import { combineReducers } from "redux";

const allReducers = combineReducers({
    profileReducer,
}
);
export type RootState = ReturnType<typeof allReducers>;
export default allReducers;

