import { Col, Row, Space, Tooltip } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import GLOBAL from 'src/variables/globals';
import IMAGES from 'src/variables/images';
import styled from 'styled-components';
import TypeButton from '../common/Buttons/TypeButton';
import * as kasModules from 'src/modules/klaytn/kas';
import { CodeNftType } from 'src/type'
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons'
import { Badge } from 'antd';
import caver from "src/modules/klaytn/caver";
import * as Type from 'src/type';
import querystring from 'query-string'
import { useLocation } from 'react-router-dom';
import { governanceContract } from 'src/Contracts/index';
import { useSelector } from 'react-redux'
import axios from 'axios';
import * as api from 'src/api/index';
import * as Utils from 'src/utils/index';
import BottomWrapper from './MyVotingPower/BottomWrapper';
import TimeOverBottomWrapper from './MyVotingPower/TimeOverBottomWrapper';
import { RootState } from "src/redux/reducers";
const klaytn = window.klaytn;
interface propsType {
    expireDate: string
}
export default function MyVotingPowerComponent(props: propsType) {
    const { expireDate } = props
    const location = useLocation();
    const agendaNo = Number(querystring.parse(location.search).id)

    const { address, vp } = useSelector((state: RootState) => state.profileReducer)

    const [nftCategory, setnftCategory] = useState("")
    const [totalNftData, settotalNftData] = useState([] as Array<CodeNftType>)
    const [nftCarts, setnftCarts] = useState([] as Array<CodeNftType>)

    const [rewardPoint, setrewardPoint] = useState(0)
    const [votedCount, setvotedCount] = useState(0)
    const [gridPageCount, setgridPageCount] = useState(1)

    const myMembershipCard = nftCarts.find(data => data.nftType == "MEMBERSHIP")
    const codeNftCardInCarts = nftCarts.filter(data => data.nftType == "CODE")
    const nftGridData = totalNftData.filter(data => {
        if (nftCategory.length > 0) {
            return data.nftType == nftCategory
        } else {
            return data
        }
    }
    ).slice((gridPageCount - 1) * 9, (gridPageCount) * 9)
    const displayNftLength = totalNftData.filter(data => {
        if (nftCategory.length > 0) {
            return data.nftType == nftCategory
        } else {
            return data
        }
    }).length
    const dummyArray = Array.from(Array(9 - (displayNftLength == 0 ? 0 : displayNftLength % 9 == 0 ? 9 : displayNftLength % 9)).keys())
    const sendVotingTx = (votingType: number) => {
        let myMembershipCardId = 0
        if (myMembershipCard) {
            myMembershipCardId = Number(myMembershipCard.tokenIdNum)
        }
        const newTokenList = nftCarts.filter(data => data.nftType == "CODE").map(data => {
            return [GLOBAL.KLAYTN.NFT_CONTRACT_ADDR, `${data.tokenIdNum}`, address]
        })
        governanceContract.methods.votingToAgendaMulti(agendaNo, votingType, newTokenList, myMembershipCardId, GLOBAL.KLAYTN.STAKING_CONTRACT_ADDR).send({ from: address, gas: 3000000 })
            .then(function (result: Type.BlockData) {
                result && alert("투표 완료!!");
                console.log(result);
                window.location.reload();
            })
            .catch((error: any) => {
                console.log(error);
                klaytn.enable();
            })
    }
    const paginationNft = (pageCal: number) => {
        if (gridPageCount == 1 && pageCal == -1) { return }
        setgridPageCount(gridPageCount + pageCal)
    }
    //nftcart 데이터 추가
    const AddItem = (newData: CodeNftType) => {
        // nft data validation
        if (newData.nftType == "MEMBERSHIP" && nftCarts.find(data => data.nftType == "MEMBERSHIP") && !nftCarts.find(data => data.nftId == newData.nftId)) {
            alert("멤버쉽 카드는 하나만 선택 할수 있습니다.")
            return
        }
        //같은 nft 선택시 없애기
        if (nftCarts.find(data => data.nftId == newData.nftId)) {
            return setnftCarts(nftCarts.filter(data => data.nftId !== newData.nftId))
        }
        const hexToNumData = Object.assign({}, newData, { tokenIdNum: caver.utils.hexToNumber(newData.tokenId) })
        setnftCarts(prev => {
            return [...prev, hexToNumData]
        })
    }
    //전체 선택
    const addAllNftCarts = () => {
        const addNftCarts = totalNftData.filter(data => {
            if (nftCategory.length > 0) {
                return data.nftType == nftCategory
            } else {
                return data
            }
        }).slice((gridPageCount - 1) * 9, gridPageCount * 9).filter(data => data.nftId !==
            nftCarts.find(prevData => data.nftId == prevData.nftId)?.nftId
        )
        setnftCarts(prev => {
            return [...prev, ...addNftCarts]
        })
    }
    useEffect(() => {
        if (address?.length > 5) {
            governanceContract.methods.getRewardPoint().call({ from: address }).then((data: any) => {
                setrewardPoint(data)
            })
            governanceContract.methods.getVotedCount(address, GLOBAL.KLAYTN.STAKING_CONTRACT_ADDR).call({ from: address }).then((data: any) => {
                setvotedCount(data)
            })
            kasModules.getAllNftTokens()?.then((values: any) => {
                settotalNftData(prev => {
                    return [...prev, ...values]
                })
            })
            api.getAllMembershipTokens(address).then(async response => {
                const PromiseList = await response.items.map((nftData: any) => {
                    return axios.get(nftData.tokenUri).then(response => { return Object.assign({}, nftData, { nftId: nftData.tokenId + nftData.tokenUri, nftType: "MEMBERSHIP", tokenName: response.data.name, tokenImage: response.data.image, }) }
                    )
                })
                await Promise.all(PromiseList).then((values: any) => {
                    settotalNftData(prev => {
                        return [...prev, ...values]
                    })
                });
            })
        }
        return () => {
        }
    }, [address])
    return (
        <Container>{
            <Wrapper>
                <HeaderSection>
                    <Row justify='space-between' align='middle'>
                        <Col>
                            <TitleText>
                                MY VOTING POWER
                            </TitleText>
                        </Col>
                        <Col>
                            <VotingStateText>
                                <VotingStateTitle>
                                    MY REWARD POINT
                                </VotingStateTitle>
                                <VotingStateContent>
                                    {Utils.priceToString(rewardPoint)}
                                </VotingStateContent>
                            </VotingStateText>
                        </Col>
                    </Row>
                </HeaderSection>
                <SubHeaderSection>
                    <Row justify='space-between'>
                        <Col>
                            <Space>
                                <TotalVpCountText>
                                    TOTAL {vp}  <span style={{ fontSize: "10px" }}>VP</span>
                                </TotalVpCountText>
                                <TotalVpCountText>
                                    MEMBERSHIP X CODE
                                </TotalVpCountText>
                            </Space>
                        </Col>
                        <Col>
                            <div style={{ marginTop: "-5px" }}>
                                <VotingStateText>
                                    <VotingStateTitle>
                                        VOTED
                                    </VotingStateTitle>
                                    <VotingStateContent>
                                        {votedCount}
                                    </VotingStateContent>
                                </VotingStateText>
                            </div>
                        </Col>
                    </Row>
                </SubHeaderSection>
                <ContentSection>
                    <Row >
                        <Col span={18} >
                            <Row gutter={[10, 10]}  >
                                <Col onClick={() => {
                                    setnftCategory("")
                                    setgridPageCount(1)
                                }} >
                                    <Badge size='small' style={{ zIndex: 100 }} color="#FB5901" count={nftCarts.length}>
                                        <TypeButton color='#FB5901' text="ALL" />
                                    </Badge>
                                </Col>
                                <Col onClick={() => {
                                    setnftCategory("CODE")
                                    setgridPageCount(1)
                                }} >
                                    <Badge size='small' style={{ zIndex: 100 }} color="#00FFCE" count={nftCarts.filter(data => data.nftType == "CODE").length}>
                                        <TypeButton color='#00FFCE' text="CODE" />
                                    </Badge>
                                </Col>
                                <Col onClick={() => {
                                    setnftCategory("MEMBERSHIP")
                                    setgridPageCount(1)
                                }}  >
                                    <div style={{ paddingRight: "10px" }}>
                                        <Badge count={nftCarts.filter(data => data.nftType == "MEMBERSHIP").length} size='small' style={{ zIndex: 100 }} color="#00FFCE" >
                                            <TypeButton color='#00FFCE' text="MEMBERSHIP" />
                                        </Badge>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6} >
                            <div onClick={addAllNftCarts}>
                                <TypeButton color='#F36600' text="SELECT ALL" />
                            </div>
                        </Col>
                    </Row>
                    {/* grid section */}
                    {address?.length > 5 ?
                        <div style={{ marginTop: "10px" }} >
                            <Row gutter={[10, 10]} >
                                {nftGridData.map((data, i) => {
                                    return (
                                        <Col style={{ backgroundColor: "#15171D" }} key={data.nftId} span={8}
                                            onClick={() => { AddItem(data) }}>
                                            <WalletNftImage key={i}>
                                                <Fragment key={i}>
                                                    {nftCarts.find(cartData => cartData.nftId == data.nftId) ?
                                                        <SelectCircle key={i} color='#F36600' >
                                                            <CircleBorder key={i} />
                                                        </SelectCircle>
                                                        :
                                                        <SelectCircle key={i} color='#3B3B3B' />
                                                    }
                                                    <img style={{ marginTop: "-20px" }} src={data.tokenImage} alt="" />
                                                    <div style={{ position: "absolute", bottom: 20, fontFamily: "-apple-system", fontWeight: 400, color: "#D1D1D1" }}>
                                                        {data.tokenName}
                                                    </div>
                                                </Fragment>
                                            </WalletNftImage>
                                        </Col>
                                    )
                                })}
                                {displayNftLength < gridPageCount * 9 &&
                                    dummyArray.map(i =>
                                        <Col style={{ backgroundColor: "#15171D" }} key={i} span={8} >
                                            <WalletNftImage key={i}>
                                                <Fragment key={i}>
                                                    <img src={IMAGES.BLACK_SCREEN} alt="" />
                                                </Fragment>
                                            </WalletNftImage>
                                        </Col>)
                                }
                            </Row>
                            <div  >
                                <Row justify='center' style={{ padding: "20px" }}>
                                    <Tooltip placement="leftBottom" title="Prev">
                                        <span style={{ cursor: "pointer" }} onClick={() => { paginationNft(-1) }}  >
                                            <CaretLeftOutlined />
                                        </span>
                                    </Tooltip>
                                    <Row >
                                        <div style={{ fontSize: "15px", paddingRight: "10px", paddingLeft: "10px" }}>{gridPageCount} </div>
                                        <div style={{ fontSize: "18px" }}>... </div>
                                        <div style={{ fontSize: "15px", paddingRight: "10px", paddingLeft: "10px" }}>{Math.ceil(totalNftData.filter(data => {
                                            if (nftCategory.length > 0) {
                                                return data.nftType == nftCategory
                                            } else {
                                                return data
                                            }
                                        }
                                        ).length / 9)} </div>
                                    </Row>
                                    {totalNftData.filter(data => {
                                        if (nftCategory.length > 0) {
                                            return data.nftType == nftCategory
                                        } else {
                                            return data
                                        }
                                    }
                                    ).length <= gridPageCount * 9 ?
                                        <Tooltip placement="leftBottom" title="End">
                                            <span>
                                                < CaretRightOutlined style={{ color: "gray" }} />
                                            </span>
                                        </Tooltip>
                                        :
                                        <Tooltip placement="rightBottom" title="Next">
                                            <span style={{ cursor: "pointer" }}
                                                onClick={() => { paginationNft(1) }}
                                            > <CaretRightOutlined />
                                            </span>
                                        </Tooltip>}
                                </Row>
                            </div>
                        </div>
                        :
                        <ConnectWalletBlank>
                            <ConnectWalletButton onClick={() => {
                                if (klaytn == undefined) {
                                    window.location.href = "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi?hl=ko"
                                } else {
                                    klaytn.enable();
                                }
                            }}>
                                connect wallet
                            </ConnectWalletButton>
                        </ConnectWalletBlank>
                    }
                </ContentSection>
            </Wrapper >}
            {Utils.isVoteTimeOut(expireDate) ?
                <BottomWrapper codeNftCardInCarts={codeNftCardInCarts} myMembershipCard={myMembershipCard} onSendVotingTx={sendVotingTx} />
                :
                <TimeOverBottomWrapper expireDate={expireDate} />}
        </Container>
    )
}
const Container = styled.div`
padding:20px;
height: 100%;
`
const Wrapper = styled.div`
background-color:#15171D ;
border-radius: 14px; 
display: flex;
flex-direction: column;
justify-content: space-around;
padding-left: 10px;
padding-right: 10px;
/* padding: 10px; */
font: normal normal bold 14px Helvetica Neue;
height: 81%; 
width: 100%
`
const ButtonWrapper = styled.div`
margin-top: 20px;
height: 20%;
background-color:#15171D ;
border-radius: 14px; 
font: normal normal bold 14px Helvetica Neue;    
`
const HeaderSection = styled.div`
padding:5px 10px 10px 10px;
border-bottom: 1px solid #0A0A0F;
display: flex;
flex-direction: column;
width: 100%;
height: 56px;
justify-content: space-between;
`
const SubHeaderSection = styled.div`
/* padding:10px; */
padding-left: 10px;
padding-right: 10px;
`
const TitleText = styled.div`
font: italic normal bold 24px Helvetica Neue;
color:${GLOBAL.COLOR.PRIMARY.WHITE};
`
const VotingStateText = styled.div`
padding-top: 10px;
padding-right: 2px;
display: flex;
flex-direction: column;
align-items: flex-end;
`
const TotalVpCountText = styled.div`
padding-top: 10px;
padding-bottom: 10px;
margin-bottom: 10px;
font-size: 14px;    
`
const ContentSection = styled.div`
padding:0px 10px 0px 10px;

`
const WalletNftImage = styled.div`
cursor: pointer;
:hover{
    background-color: #373A43;
}
background: #1E1F22 0% 0% no-repeat padding-box;
display: flex;
width: 100%;
align-items: center;
justify-content: center;
/* width: 140px; */
/* height: 120px; */
:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
& img{
    width: 50%;
    height: 50%;
    border-radius: 50%; 
    
}
`
const SelectCircle = styled.div`
margin-top: 6px;
position: absolute;
top: 0;
right: 0;
margin-right: 12px;
border-radius: 50%;
width: 16px;
height: 16px;
background: ${props => props.color} 0% 0% no-repeat padding-box;
border: 1px solid ${props => props.color};
opacity: 1;
`
const VotingButton = styled.div`
:hover{
    background-color: ${GLOBAL.COLOR.PRIMARY.RED};
}
font: normal normal bold 12px Noto Sans CJK KR;
color:#ffffff;
background: ${props => props.color} ;
display: flex;
justify-content: center;
align-items: center;
/* width: 148px; */
height: 36px;
border-radius: 4px;
`
const VotingStateTitle = styled.div`
padding:2px;
/* padding-right: 10px; */
font: normal normal bold 10px Helvetica Neue;
color: #FFFFFF;   
`
const VotingStateContent = styled.div`
margin-right: 5px;

font: italic normal bold 24px Helvetica Neue;
color: #FFFFFF;    
`
const CircleBorder = styled.div`
/* position: absolute;
top: 0;
right: 0; */
/* margin-right: 3px; */
margin-top: -4px;
margin-left: -4px;
border-radius: 50%;
width:22px;
height: 22px;
border-radius: 50%;
border: 1px solid #F36600;    
`
const FooterSection = styled.div`
padding: 10px;
`
const ConnectWalletBlank = styled.div`
width: 100%;
height: 500px; 
display: flex;
justify-content: center;
align-items: center;
`
const ConnectWalletButton = styled.div`
cursor: pointer;

width: 262px;
height: 56px;
background: #1E1F22 0% 0% no-repeat padding-box;
box-shadow: 3px 3px 6px #00000029;
border-radius: 4px;
display: flex;
justify-content: center;
align-items: center;
:hover{
    background-color: #F36600;
}    
`
