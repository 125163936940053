export const governaceDescMenuDummy = [
    {
        title: "VOTING ELIGIBLITY",
        content: "CODE"
    },
    {
        title: "REWARD",
        content: "VOTING REWARD POINT"
    },
    {
        title: "WARNING",
        content: "YOUR NFT IS STAKING UNTIL VOTING ENDS"
    },
    {
        title: "DECISION QUORUM",
        content: "400,000"
    },
]
export const pageLists = [
    {
        title: "MAIN",
        route: "main"
    }
    ,
    {
        title: "GOVERNANCE",
        route: "governance"
    }
    ,
    {
        title: "WALLET",
        route: "wallet"
    }
    ,
    {
        title: "SHOP",
        route: "shop"
    }
    ,
    {
        title: "STAKING",
        route: "staking"
    },
    {
        title: "BRIDGE",
        route: "bridge"
    },
    {
        title: "MELT",
        route: "melt"
    }

]