import { Layout, BackTop } from "antd";
import HeaderComponent from "src/components/common/Header";
import FooterComponent from 'src/components/common/Footer'
import SiderComponent from 'src/components/common/Sider';
import PrivateRoute from "src/router/PrivateRoutes";
import { useState } from "react";
const { Content, Sider, Footer } = Layout;
export default function Basic() {
    return (
        <>
            <Layout>
                <Sider width={300} theme="light" >
                    <SiderComponent />
                </Sider>
                <Layout >
                    <HeaderComponent />
                    <Content style={{ backgroundColor: "#0B0A0F" }}>
                        <BackTop />
                        <PrivateRoute />
                    </Content>

                </Layout>
            </Layout>
            <FooterComponent />
        </>
    )
}
